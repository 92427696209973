import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FoodMenu from './components/FoodMenu/FoodMenu';
import FoodCart from './components/FoodCart/FoodCart';
import FoodNavigation from './components/FoodNavigation/FoodNavigation';
import {getUniqueNameWithTimeStamp, checkInternetConnection,checkInternetConnectionWithBrowserNavigation,cashThroughTypes } from '../../utils/general';
import LoadingCircle from '../../common/components/LoadingCircle';
import { socket } from '../../layout/Header';
import {showMsg,addTimeToCurrentDateTime} from '../../utils/general';
// import {ORIGIN_ADDRESS} from '../../utils/misc';
import { fetchResturantTax, fetchRestaurantCardProcessingFee, fetchRestaurantProvince, fetchRestaurantName,fetchResturantDeliveryFees,fetchResturantAddress,fetchResturantLogo  } from '../../../actions/settings_actions';
import orderNotificationAudio from '../../common/audio/order_notification.mp3';
import tabletOrderNotificationAudio from '../../common/audio/tablet/tablet_order_notification_sound.mp3';
import { fetchOrders,fetchWebOrders,fetchMistakeOrders,fetchOrderByOId,fetchWebOrderNotificationsCount,fetchTabletOrderNotificationsCount,clearOrderHistory,submitCreateOrder,submitEditOrder,fetchMaxTicketNumberOfOrder,clearExistingOrderHistory,clearMistakeExistingOrderHistory,clearWebExistingOrderHistory,fetchTodayExistingOrders,fetchTodayMistakeOrders,deleteOrderByOId,fetchNewTabletNotificationOrders,fetchExistingTabletNotificationOrders,clearNewTabletNotificationOrdersHistory,clearExistingTabletNotificationOrdersHistory } from '../../../actions/order_actions';
import { fetchMenuCatgories,getMenuItemsByCategoryId,clearMenuCategoryMenuItems } from '../../../actions/menu_category_actions';
import {fetchMenuItems,fetchIngredients,fetchIngredientMenuItems} from '../../../actions/menu_actions';
import {
    fetchMenuItemCombosByMenuItemId,
    fetchMenuItemByIdWithIngredients,
    clearMenuItemComboItems,
    clearMenuItemIngredients
} from '../../../actions/menu_item_actions';
import {
    clearCart,
    addToCart,
    addIncludedAndBothIngredientsToMenuItemCart,
    addIncludedAndBothIngredientsToComboMenuItemCart,
    removeCartItem,
    removeIngredientFromCartMenuItem,
    removeCartSubItem,
    removeIngredientFromCartComboItem,
    addComboItemToMenuItemCart,
    addIngredientToMenuItemCartA,
    addIngredientToMenuItemComboItemCart,
    addNotesWithExtraChargeToCart,
    addNotesWithExtraChargeToCartMenuItem,
    addNotesWithExtraChargeToCartMenuItemComboItem,
    removeNotesFromCart,
    addRefundedItemToCart,
    addExistingOrderToCart,
} from '../../../actions/cart_actions';
import OrderReceiptPopup from './components/OrderReceiptPopup/OrderReceiptPopup';
import localForage from 'localforage';
import LoadingOverlay from 'react-loading-overlay'
import { calculateFinalTotalOfOrderWithOutObjOrder, calculateFinalTotalOfOrder, calculateTotalIncludingFee } from '../../utils/helpers/OrdersPages/OrdersHelpers';

class POS extends Component {
    _isMounted = false;
    state = {
        isSystemBeingUpdate:false,
        orderResponse:{},
        loading: true,
        lastOrderId:'',
        selectedOrderType:'takeout',
        menuCategoryId:'',
        menuCategoryItemId:'',
        menuCategoryItem:{},
        isShowMenuItemSizeCircles:false,
        isShowMenuItemSpecialModifiersCircles:false,
        selected_menu_item_timestamp:'',
        selectedMenuItemComboItemId:'',
        selectedMenuItemIngredientTypeCategory:'',
        selectedItemType:'',
        selectedCustomerType:'',
        selectedMistakeType:'',
        loadingMenuCatgoeries: true,
        loadingMenuCatgoeryItems: false,
        loadingMenuItemComboItems: false,
        loadingMenuItemWithIngredients: false,
        limitMenuItems:8000,
        limitMenuItemComboItems:8000,
        limitSauceIngredients:7000,
        limitMeatCheeseIngredients:7000,
        limitMiscIngredients:7000,
        showCustomerInformationModal : false,
        showNotePadModal : false,
        showIsNewExistingCustomerSelectionModal : false,
        showIsNewExistingTabletOrderNotificationSelectionModal : false,
        showExistingCustomerModal : false,
        showExistingOrdersModal:false,
        showMistakeExistingOrdersModal:false,
        isloadingMistakeExistingOrders:false,
        showDiscountModal:false,
        showDoneModal:false,
        // showDoneModal:false,
        showCashModal:false,
        showIsNewExistingMistakeSelectionModal : false,
        showWebOrdersNotificationModal : false,
        tax : 0,
        taxAmount:0,
        subTotal:0.00,
        total:0.0,
        totalAmount:0,
        changeAmount:0,
        delivery_amount:0,
        distance_in_kilo:'',
        objCustomer:{}, 
        cartDetail:[],
        discount_percentage:0,
        discount_amount_entered:0,
        discount_amount:0,
        isShowLoader:false,
        isloadingExistingOrders:false,
        isloadingWebExistingOrders:false,
        selectedOnReceiptItemType:'',
        selectedOnReceiptMenuItemId:'',
        selectedOnReceiptMenuItemTimeStamp:'',
        selectedOnReceiptMenuItemIngredientId:'',
        selectedOnReceiptMenuItemIngredient:{},
        selectedOnReceiptMenuItemComboItemId:'',
        selectedOnReceiptMenuItemComboItemIngredientId:'',
        selectedOnReceiptMenuItemComboItemIngredient:{},
        isRefundActive:false,
        isMistakeActive:false,
        isOrderPrint: false, 
        isOrderNoKitchen: false, 
        mistake_type:'',
        result:"",
        customerGivenAmount:"",
        payment_method:"",
        // Address Form States
        // origin:'6082 Tecumseh Rd E, Windsor, ON N8T 1E3, Canada',
        // origin:'Forks n Knives Crisp n Grill, Susan Road, Faisalabad, Pakistan',
        origin:'',
        // origin:ORIGIN_ADDRESS,
        city: '',
        query: '',
        // delivery_type:'',
        delivery_type:'takeout',
        isCustomerSavingLoader:false,
        customerFormData : {},
        selectedPhoneNumber:{},
        // tax : 0,
        // delivery_amount:0,
        // distance_in_kilo:'',
        time_picker_time:'',
        value: '', 
        phone_no: '', 
        suggestions: [],
        // Load more existing ordrs
        limitExstingOrders:7,
        skipExstingOrders:0,
        limitWebExstingOrders:7,
        skipWebExstingOrders:0,
        limitMistakeExstingOrders:7,
        skipMistakeExstingOrders:0,
        existingOrders:[],
        lastSelectedItem:{},
        existing_order_id:'',
        selectedExistingOrder:{},
        play: false,
        playTabletOrderAuido: false,
        loadingOrderNotificationCount:false,
        WebOrderNotificationsCount:0,
        loadingTabletOrderNotificationCount:false,
        TabletOrderNotificationsCount:0,
        drp_default_time:'',
        timeSelectionRadioBox:'',

        isloadingTabletExistingOrders:false,
        isloadingTabletNewOrders:false,
        selectedTabletOrderNotificationType:'',
        showNewTabletOrderNotificationModal:false,
        isloadingNewTabletNotificationOrders:false,
        showExistingTabletOrderNotificationModal:false,
        isloadingExistingTabletNotificationOrders:false,
        limitTabletNewNotificationOrders:7,
        skipTabletNewNotificationOrders:0,
        limitTabletExistingNotificationOrders:7,
        skipTabletExistingNotificationOrders:0,
        deliveryFees:{},
        selectedMenuItem:{},
        selectedComboItem:{},
        selectedComboItemIngredient:{},
        // Special Modifers 
        selectedSpecialModifier:{},
        selectedSpecialModifierName:'',
        selectedSpecialModifierPrice:'',

        restaurant_name:'',
        restaurant_province:'',
        restaurant_province_id:'',
        card_processing_fee:false,
        selectedDeliveryPaymentMethod:'',
    }
    audio = new Audio(orderNotificationAudio);
    tabletOrderNotificationAudio = new Audio(tabletOrderNotificationAudio);
    
    playNotificationAudioPlay = () => {
        // console.log('this.audio')
        // console.log('this.audio')
        // console.log(this.audio)
        this.setState({ play: true }, () => {
            if(this.state.play){
                this.audio.loop = true
                this.audio.load();
                // this.audio.play();
                this.playAudio();
            }else{
                this.audio.loop = false;
                this.audio.load();
                this.audio.pause()
            }
            // this.state.play ? this.audio.play() : this.audio.pause();
        });
    }
    toggleNotificationAudioPlay = () => {
        // console.log('this.audio')
        // console.log('this.audio')
        // console.log(this.audio)
        this.setState({ play: !this.state.play }, () => {
            if(this.state.play){
                this.audio.loop = true
                this.audio.load();
                // this.audio.play();
                this.playAudio();
            }else{
                this.audio.loop = false;
                this.audio.load();
                this.audio.pause()
            }
            // this.state.play ? this.audio.play() : this.audio.pause();
        });
    }
    playAudio(){
        const audioPromise = this.audio.play()
        if (audioPromise !== undefined) {
        audioPromise
            .then(_ => {
                // autoplay started
                // console.log('i am in prmose');
                // console.log(_)
                // console.log(_)
                // console.log(_)
            })
            .catch(err => {
                // catch dom exception
                console.info('err in playing audio')
                console.info(err)
            })
        }
    }

    toggleTabletOrderNotificationAudioPlay = () => {
        // console.log('this.audio')
        // console.log('this.audio')
        // console.log(this.audio)
        this.setState({ playTabletOrderAuido: !this.state.playTabletOrderAuido }, () => {
            if(this.state.playTabletOrderAuido){
                this.tabletOrderNotificationAudio.loop = true
                this.tabletOrderNotificationAudio.load();
                // this.tabletOrderNotificationAudio.play();
                this.playTabletOrderAudio();
            }else{
                this.tabletOrderNotificationAudio.loop = false;
                this.tabletOrderNotificationAudio.load();
                this.tabletOrderNotificationAudio.pause()
            }
            // this.state.play ? this.audio.play() : this.audio.pause();
        });
    }
    playTabletOrderAudio(){
        const audioPromise = this.tabletOrderNotificationAudio.play()
        if (audioPromise !== undefined) {
        audioPromise
            .then(_ => {
                // autoplay started
            })
            .catch(err => {
                // catch dom exception
                console.info('err in playing audio')
                console.info(err)
            })
        }
    }

    componentDidMount() {
        this._isMounted = true;
        /* socket.on('connect', () => {
            console.log(socket.connected); 
        }); */
        // console.log('this.props.cart')
        // console.log(this.props.cart)
        this.updateSystem(false);
        if (this._isMounted) {
            this.setState({loadingOrderNotificationCount:true,loadingTabletOrderNotificationCount:true})
        }
        
        this.props.fetchWebOrderNotificationsCount().then(response =>{
            if (this._isMounted) {
                console.log('this.props.order.WebOrderNotificationsCount')
                console.log('this.props.order.WebOrderNotificationsCount')
                console.log(this.props.order.WebOrderNotificationsCount)
                if(this.props.order.WebOrderNotificationsCount > 0){
                    this.toggleNotificationAudioPlay();
                }
                this.setState({WebOrderNotificationsCount:this.props.order.WebOrderNotificationsCount,loadingOrderNotificationCount:false})
            }
        });
        this.OrderNotificationsCountInterval = setInterval(() => this.checkOrderNotificationsCountInterval(), 1000);
        this.props.fetchTabletOrderNotificationsCount().then(response =>{
            if (this._isMounted) {
                console.log('this.props.order.TabletOrderNotificationsCount')
                console.log('this.props.order.TabletOrderNotificationsCount')
                console.log(this.props.order.TabletOrderNotificationsCount)
                // if(this.props.order.TabletOrderNotificationsCount > 0){
                //     this.toggleTabletOrderNotificationAudioPlay();
                // }
                this.setState({TabletOrderNotificationsCount:this.props.order.TabletOrderNotificationsCount,loadingTabletOrderNotificationCount:false})
            }
        });
        this.props.fetchMenuCatgories().then(response =>{
            // console.log('response in home fetchMenuCatgories')
            // console.log(response)
            if (this._isMounted) {
                this.setState({loadingMenuCatgoeries:false})
            }
            // this.setState({loading:false})
        });
        // this.props.fetchResturantTax().then(response => {
        //     if (this._isMounted) {
        //         this.setState({tax:this.props.objtax.tax});
        //         this.calculateTotal(this.props.cart);
        //     }
        // });
        this.props.fetchRestaurantCardProcessingFee().then(response => {
            if (this._isMounted) {
                this.setState({card_processing_fee:this.props.objRestaurantCardProcessingFee.card_processing_fee});
            }
        });
        this.calculateTotal(this.props.cart);
        this.props.fetchRestaurantProvince().then(response => {
            if (this._isMounted) {
                let restaurant_province = this.props.objRestaurantProvince.restaurant_province;
                let restaurant_province_id = this.props.objRestaurantProvince.restaurant_province_id;
                let tax = 0;
                // if(restaurant_province.toLowerCase() === 'ontario'){
                //     if(parseFloat(this.state.subTotal) <= 4){
                //         tax = 5; 
                //     }else if(parseFloat(this.state.subTotal) > 4){
                //         tax = 13; 
                //     }
                // }
                this.setState({ tax,restaurant_province,restaurant_province_id },()=>{
                    this.calculateTotal(this.props.cart);
                });
            }
        });
        this.props.fetchRestaurantName().then(response => {
            // console.log('this.props.objtax in resp');
            // console.log(this.props.objtax);
            if (this._isMounted) {
                this.setState({restaurant_name:this.props.objRestaurantName.restaurant_name});
            }
            // console.log(this.props.objtax);
        });
        
        this.props.fetchResturantDeliveryFees().then(response => {
            // console.log('this.props.objRestaurantDeliveryFees in resp');
            // console.log('this.props.objRestaurantDeliveryFees in resp');
            // console.log('this.props.objRestaurantDeliveryFees in resp');
            this.setState({deliveryFees:this.props.objRestaurantDeliveryFees.delivery_fees});
            // console.log(this.props.objtax);
        });
        this.props.fetchResturantAddress().then(response => {
            // console.log('this.props.objRestaurantAddress in resp');
            // console.log('this.props.objRestaurantAddress in resp');
            // console.log('this.props.objRestaurantAddress in resp');
            this.setState({origin:this.props.objRestaurantAddress.restaurant_address,restaurant_address:this.props.objRestaurantAddress.restaurant_address});
        });
        this.props.fetchResturantLogo().then(response => {
            console.log('this.props.objRestaurantLogo in resp');
            console.log('this.props.objRestaurantLogo in resp');
            console.log('this.props.objRestaurantLogo in resp');
            console.log(this.props.objRestaurantLogo.restaurant_logo)
            this.setState({headerLogo:this.props.objRestaurantLogo.restaurant_logo});
        });
        
        this.props.fetchMaxTicketNumberOfOrder().then(response => {
            if (this._isMounted) {
                this.setState({maxTicketNumber:this.props.order.maxTicketNumber});
            }
        });
        socket.on("orders-channel:closeWebOrderAudio",  (message) => {
            // console.log('message in closeWebOrderAudio');
            // console.log(message);
            if(this.state.play){
                this.toggleNotificationAudioPlay();
            }
        });
        socket.on("orders-channel:closeTabletNotificationNewOrderAudio",  (message) => {
            console.log('message in closeTabletNotificationNewOrderAudio');
            console.log('message in closeTabletNotificationNewOrderAudio');
            console.log('message in closeTabletNotificationNewOrderAudio');
            console.log(message);
            if(this.state.playTabletOrderAuido){
                this.toggleTabletOrderNotificationAudioPlay();
            }
        });
        
        socket.on("orders-channel:orderPlacedFromWeb",  (message) => {
            console.log('message in orderPlacedFromWeb');
            console.log(message);
            this.increment('WebOrderNotificationsCount');
            this.toggleNotificationAudioPlay();
        });
        socket.on("orders-channel:OrderWebNotificationsCountDecreaseEmit",  (message) => {
            console.log('message in OrderWebNotificationsCountDecreaseEmit');
            console.log(message);
            this.decrement('WebOrderNotificationsCount')
        });
        socket.on("orders-channel:orderPlacedFromTablet",  (message) => {
            console.log('message in orderPlacedFromTablet');
            console.log('message in orderPlacedFromTablet');
            console.log('message in orderPlacedFromTablet');
            console.log(message);
            this.increment('TabletOrderNotificationsCount');
            this.toggleTabletOrderNotificationAudioPlay();
        });
        socket.on("orders-channel:TabletOrderNotificationsCountDecreaseEmit",  (message) => {
            console.log('message in TabletOrderNotificationsCountDecreaseEmit');
            console.log(message);
            this.decrement('TabletOrderNotificationsCount')
        });
        this.props.fetchMenuItems().then(response => {
        });
        this.props.fetchIngredients().then(response => {
        });
        this.props.fetchIngredientMenuItems().then(response => {
        });
        this.props.fetchTodayExistingOrders(
            'pos',
            'regular',
            'done',
            new Date(),
            1000,
            1000,
            this.props.order.todayExistingOrders
        ).then(response => {
            // console.log('this.props.order');
            // console.log(this.props.order);
        });
        this.props.fetchTodayMistakeOrders(
            'pos',
            'mistake',
            'done',
            new Date(),
            1000,
            1000,
            this.props.order.todayMistakeExistingOrders
        ).then(response => {
        });

        // Presentation Api
        // this.presentationRequest = new PresentationRequest('http://localhost:4000/kitchen');
        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        // console.log(isChrome)
        if (isChrome){
            this.presentationRequest = new PresentationRequest('https://honeyballz.jubzi.com/kitchen');
            this.presentationRequest.getAvailability()
            .then(availability => {
                // console.log('Available presentation displays: ' + availability.value);
                availability.addEventListener('change', function () {
                    // console.log('> Available presentation displays: ' + availability.value);
                });
                
            })
            .catch(error => {
                console.log('Presentation availability not supported, ' + error.name + ': ' +
                    error.message);
            });
        }

        window.pos = this;
        window.showMsg = showMsg;
        // this.toggleNotificationAudioPlay();
    }
    /* Removing the listener before unmounting the component in order to avoid addition of multiple listener at the time revisit*/

    componentWillUnmount() {
        socket.off("orders-channel:orderPlacedFromWeb");
        socket.off("orders-channel:closeWebOrderAudio");
        socket.off("orders-channel:OrderWebNotificationsCountDecrease");
        socket.off("orders-channel:OrderWebNotificationsCountDecreaseEmit");
        socket.off("orders-channel:orderPlacedFromTablet");
        socket.off("orders-channel:closeTabletNotificationNewOrderAudio");
        socket.off("orders-channel:TabletOrderNotificationsCountDecrease");
        socket.off("orders-channel:TabletOrderNotificationsCountDecreaseEmit");
        clearInterval(this.OrderNotificationsCountInterval);
        this._isMounted = false;
    }
    
    checkOrderNotificationsCountInterval = () => {
        // console.log(this.state.WebOrderNotificationsCount)
        // console.log(this.state.WebOrderNotificationsCount)
        if(this.state.WebOrderNotificationsCount > 0){
            // this.playNotificationAudioPlay();
        }
    }
    updateSystem = async (isReload = true) => {
        if (this._isMounted) {
            let isConnected = await checkInternetConnectionWithBrowserNavigation();
            if(isConnected){
                this.setState({isSystemBeingUpdate:true});
                await localForage.removeItem('fetch-menu-categories');
                await localForage.removeItem('fetch-menu-items');
                await localForage.removeItem('fetch-ingredients');
                this.props.fetchMenuCatgories().then(response =>{
                    // console.log('response in home fetchMenuCatgories')
                    // console.log(response)
                });
                this.props.fetchMenuItems().then(response => {
                });
                this.props.fetchIngredients().then(response => {
                });
                this.props.fetchIngredientMenuItems().then(response => {
                    this.setState({isSystemBeingUpdate:false});
                    if(!isReload){
                        showMsg("System Has been updated successfully.",'success');
                    }
                });
                
                this.props.fetchResturantDeliveryFees().then(response => {
                    // console.log('this.props.objRestaurantDeliveryFees in resp');
                    // console.log('this.props.objRestaurantDeliveryFees in resp');
                    // console.log('this.props.objRestaurantDeliveryFees in resp');
                    if(!isReload){
                        this.setState({deliveryFees:this.props.objRestaurantDeliveryFees.delivery_fees});
                    }
                    // console.log(this.props.objtax);
                });
                if(isReload){
                    showMsg("System Has been updated successfully.",'success');
                    window.location.reload();  
                }
            }
        }
    }
    openPresentationUrl = () => {
        if (this._isMounted) {
            let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
            if (isChrome){
                this.presentationRequest.start()
                .then(connection => {
                    console.log('Connected to ' + connection.url + ', id: ' + connection.id);
                })
                .catch(error => {
                    console.log(error);
                });
            }else{
                alert('This featuer is only available in Chrome')
            }
        }
    }
    increment = (propName) => {
        this.setState(prevState => ({[propName]: ++prevState[propName]}))
    }
    decrement = (propName) => {
        this.setState(prevState => ({[propName]: --prevState[propName]}))
    }
    // es6 function, will be bind with adding .bind(this)
    handleOnClickOrderType = (order_type) => {
        if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            showMsg("You can not select delivery type in new mistake mode.",'error')
        }else{
            this.setState({selectedOrderType:order_type});
            if(order_type === 'pickup' || order_type === 'delivery'){
                if(this.state.isMistakeActive){
                    this.setState({showCustomerInformationModal:true});
                }else{
                    if(this.state.existing_order_id !== ''){
                        this.setState({showCustomerInformationModal:true});
                    }else{
                        this.setState({showIsNewExistingCustomerSelectionModal:true});
                    }
                }
            }
        }
    }

    handleOnChangeCustomerDetailsState = (updatedValues) => {
        // console.log('updatedValues in handleOnChangeCustomerDetailsState')
        // console.log('updatedValues in handleOnChangeCustomerDetailsState')
        // console.log('updatedValues in handleOnChangeCustomerDetailsState')
        // console.log(updatedValues)
        // this.setState({...this.state,...updatedValues});
        this.setState(prevState => {
            // Object.assign would also work
            return {...prevState, ...updatedValues};
        });
    }
    handleOnChangeDrfDefaultTime = (drp_default_time) =>{
        this.setState({drp_default_time}); 
    }
    handleOnChangetimeSelectionRadioBox = (timeSelectionRadioBox) =>{
        this.setState({timeSelectionRadioBox}, () => {
            console.log('timeSelectionRadioBox after in pos')
            console.log(this.state.timeSelectionRadioBox)
        }); 
    }
    

    handleOnChangeCustomerModalToggle = (showCustomerInformationModal) =>{
        this.setState({showCustomerInformationModal:showCustomerInformationModal},()=>{
            if(this.state.showCustomerInformationModal === false){
                if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
                    // alert(Object.keys(this.state.objCustomer).length)
                    if(Object.keys(this.state.objCustomer).length === 0){
                        this.handleOnClickVoidMenu();
                    }
                }
            }
        });
        // this.setState({showCustomerInformationModal:!showCustomerInformationModal});
    }
    handleOnChangeNotePadToggle = (showNotePadModal) =>{
        this.setState({showNotePadModal:showNotePadModal});
    }
    handleOnChangeNewOrExistingCustomerModalToggle = (showIsNewExistingCustomerSelectionModal) =>{
        this.setState({showIsNewExistingCustomerSelectionModal:showIsNewExistingCustomerSelectionModal});
    }
    handleOnChangeExistingOrderModalToggle  = (showExistingOrdersModal) =>{
        this.setState({showExistingOrdersModal:showExistingOrdersModal},
            () => {
              if(this.state.showExistingOrdersModal === false){
                  this.props.clearExistingOrderHistory();
              }
        });
    }
    handleOnChangeMistakeExistingOrderModalToggle  = (showMistakeExistingOrdersModal) =>{
        this.setState({showMistakeExistingOrdersModal:showMistakeExistingOrdersModal},
            () => {
                if(this.state.showMistakeExistingOrdersModal === false){
                    this.props.clearMistakeExistingOrderHistory();
                }
        });
    }
    showWebNotificationsModal = () =>{
        /* if(this.state.play){
            this.toggleNotificationAudioPlay();
            socket.emit("closeWebOrderAudio", 'audio_close');
        } */
        this.setState({isloadingWebExistingOrders:true,showWebOrdersNotificationModal:true})
        this.props.fetchWebOrders(
            'web',
            'regular',
            'done',
            this.state.skipWebExstingOrders,
            this.state.limitWebExstingOrders,
            this.props.order.webExistingOrders
        ).then(response => {
            // console.log('this.props.order');
            // console.log(this.props.order);
            this.setState({isloadingWebExistingOrders:false});
        });
    }
    handleOnChangeWebOrdersNotificationModalToggle  = (showWebOrdersNotificationModal) =>{
        // console.log('handleOnChangeWebOrdersNotificationModalToggle')
        // console.log(showWebOrdersNotificationModal)
        this.setState({showWebOrdersNotificationModal:showWebOrdersNotificationModal},
            () => {
              if(this.state.showWebOrdersNotificationModal === false){
                  this.props.clearWebExistingOrderHistory();
              }else if(this.state.showWebOrdersNotificationModal){
                /* this.props.fetchWebOrders(
                    'web',
                    'regular',
                    'done',
                    this.state.skipWebExstingOrders,
                    this.state.limitWebExstingOrders,
                    this.props.order.webExistingOrders
                ).then(response => {
                    // console.log('this.props.order');
                    // console.log(this.props.order);
                    this.setState({isloadingWebExistingOrders:false});
                }); */
              }
        });
    }
    handleOnClickCustomerTypeSelection = (event,customer_type) => {
        // console.log(customer_type)
        // console.log(customer_type)
        // console.log(customer_type)
        this.setState({showIsNewExistingCustomerSelectionModal:false,selectedCustomerType:customer_type});
        if(customer_type === 'new'){
            this.setState({showCustomerInformationModal:true});
        }else if(customer_type === 'existing'){
            this.setState({showExistingOrdersModal:true,isloadingExistingOrders:true});
            this.props.fetchOrders(
                'pos',
                'regular',
                'done',
                this.state.skipExstingOrders,
                this.state.limitExstingOrders,
                this.props.order.existingOrders
            ).then(response => {
                // console.log('this.props.order');
                // console.log(this.props.order);
                this.setState({isloadingExistingOrders:false});
            });
        }
    }

    // Tablet Order
    handleOnChangeNewOrExistingTabletOrderNotificationSelectionModal = (showIsNewExistingTabletOrderNotificationSelectionModal) =>{
        this.setState({showIsNewExistingTabletOrderNotificationSelectionModal});
    }
    handleOnClickTabletOrderNotificationTypeSelection = (event,selectedTabletOrderNotificationType) => {
        console.log('selectedTabletOrderNotificationType')
        console.log('selectedTabletOrderNotificationType')
        console.log(selectedTabletOrderNotificationType)
        this.setState({showIsNewExistingTabletOrderNotificationSelectionModal:false,selectedTabletOrderNotificationType});
        if(selectedTabletOrderNotificationType === 'new'){
            this.setState({showNewTabletOrderNotificationModal:true,isloadingNewTabletNotificationOrders:true});
            this.props.fetchNewTabletNotificationOrders(
                'tablet',
                'regular',
                null,
                this.state.skipTabletNewNotificationOrders,
                this.state.limitTabletNewNotificationOrders,
                this.props.order.newTabletNotificationOrders
            ).then(response => {
                // console.log('this.props.order');
                // console.log(this.props.order);
                this.setState({isloadingNewTabletNotificationOrders:false});
            });
        }else if(selectedTabletOrderNotificationType === 'existing'){
            this.setState({showExistingTabletOrderNotificationModal:true,isloadingExistingTabletNotificationOrders:true});
            this.props.fetchExistingTabletNotificationOrders(
                'tablet',
                'regular',
                'done',
                this.state.skipTabletExistingNotificationOrders,
                this.state.limitTabletExistingNotificationOrders,
                this.props.order.existingTabletNotificationOrders
            ).then(response => {
                // console.log('this.props.order');
                // console.log(this.props.order);
                this.setState({isloadingExistingTabletNotificationOrders:false});
            });
        }
    }
    onClickHandleLoadMoreNewTabletNotificationOrderItems = () => {
        console.log('onClickHandleLoadMoreNewTabletNotificationOrderItems')
        this.setState({isloadingNewTabletNotificationOrders:true});
        let skipTabletNewNotificationOrders = this.state.skipTabletNewNotificationOrders + this.state.limitTabletNewNotificationOrders;
        this.props.fetchNewTabletNotificationOrders(
                'tablet',
                'regular',
                null,
                skipTabletNewNotificationOrders,
                this.state.limitTabletNewNotificationOrders,
                this.props.order.newTabletNotificationOrders
            ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            this.setState({skipTabletNewNotificationOrders,isloadingNewTabletNotificationOrders:false});
        });
    }
    clearNewTabletNotificationOrderLimits = ()=>{
        this.setState({limitTabletNewNotificationOrders:7,skipTabletNewNotificationOrders:0},
            () => {
                // console.log('this.state.limitTabletNewNotificationOrders in clearNewTabletNotificationOrderLimits')
                // console.log('this.state.limitTabletNewNotificationOrders in clearNewTabletNotificationOrderLimits')
                // console.log('this.state.limitTabletNewNotificationOrders in clearNewTabletNotificationOrderLimits')
                // console.log(this.state.limitTabletNewNotificationOrders)
                // console.log(this.state.skipTabletNewNotificationOrders)
                
        });
    }
    handleOnChangeNewTabletNotificationModalToggle  = (showNewTabletOrderNotificationModal) =>{
        this.setState({showNewTabletOrderNotificationModal},
            () => {
                console.log('this.state.showNewTabletOrderNotificationModal in handleOnChangeNewTabletNotificationModalToggle')
                console.log('this.state.showNewTabletOrderNotificationModal in handleOnChangeNewTabletNotificationModalToggle')
                console.log('this.state.showNewTabletOrderNotificationModal in handleOnChangeNewTabletNotificationModalToggle')
                console.log(this.state.showNewTabletOrderNotificationModal)
                if(this.state.showNewTabletOrderNotificationModal === false){
                    this.props.clearNewTabletNotificationOrdersHistory();
                    this.clearNewTabletNotificationOrderLimits();
                }else if(this.state.showNewTabletOrderNotificationModal){
                }
        });
    }
    showTabletNotificationsModal = () =>{
        /* if(this.state.play){
            this.toggleNotificationAudioPlay();
            socket.emit("closeWebOrderAudio", 'audio_close');
        } */
        this.setState({showIsNewExistingTabletOrderNotificationSelectionModal:true})
        
    }
    handelOnClickTabletNewOrder = (order) => {
        console.log('onClickExistingOrder')
        console.log('order')
        console.log(order);
        if(this.state.playTabletOrderAuido){
            this.toggleTabletOrderNotificationAudioPlay();
            socket.emit("closeTabletNotificationNewOrderAudio", 'audio_close');
        }
        let existing_order_id = order._id;
        // let selectedExistingOrder = order;
        // console.log('order_id')
        // console.log(order_id)
        this.props.clearNewTabletNotificationOrdersHistory();
        this.props.clearExistingTabletNotificationOrdersHistory();
        this.setState({isShowLoader:true,showNewTabletOrderNotificationModal:false,showExistingTabletOrderNotificationModal:false});
        this.handleOnClickVoidMenu();
        this.props.fetchOrderByOId(existing_order_id).then(response => {
            // console.log('this.props.order.Order');
            // console.log(this.props.order.Order);

            this.props.addExistingOrderToCart(this.props.order.Order.order_details).then(response => {
                this.calculateTotal(this.props.cart)
            })
            // changing the values
            let objState = {};
            const objOrder = this.props.order.Order;
            let objCustomer = objOrder.objCustomer;
            let customerFormData = objCustomer;
            console.log('customerFormData')
            console.log(customerFormData)
            // let values = this.state.objCustomer;
            // objOrder.address = this.state.objCustomer.query;
            // objOrder.phone_no = this.state.objCustomer.phone_no;

            /* if(objOrder.timeSelectionRadioBox === "have_ready_in"){
                objOrder.order_completion_datetime = addTimeToCurrentDateTime(objOrder.drp_default_time);
            }else if(objOrder.timeSelectionRadioBox === "other_time"){
                objOrder.order_completion_datetime = new Date(objOrder.time_picker_time);
            } */
            objState.subTotal = objOrder.subTotal; 
            objState.total = objOrder.total; 
            objState.selectedDeliveryPaymentMethod = objOrder.delivery_payment_method; 
            objState.total = objOrder.total_amount; 
            objState.delivery_amount = objOrder.delivery_amount; 
            objState.distance_in_kilo = objOrder.distance_in_kilo; 
            objState.discount_amount = objOrder.discount_amount; 
            objState.discount_percentage = objOrder.discount_percentage; 
            objState.discount_amount_entered = objOrder.discount_amount_entered; 
            objState.taxAmount = objOrder.taxAmount; 
            objState.selectedOrderType = objOrder.delivery_type; 
            objState.payment_method = objOrder.payment_method;
            if(objOrder.customer_given_amount !== null ){
                objState.customerGivenAmount = objOrder.customer_given_amount; 
            }else{
                objState.customerGivenAmount = ''; 
            } 
            if(objOrder.phone_no !== undefined){
                objState.phone_no = objOrder.phone_no;
                objState.value = objOrder.phone_no;
            }else{
                objState.phone_no = '';
                objState.value = '';
            }
            objState.maxTicketNumber = objOrder.ticket_no;
            objState.address = objOrder.address;
            objState.query = objOrder.address;
            // console.log('objState after done')
            // console.log('objState after done')
            // console.log(objState)
            // objState.tax = objOrder.tax_per;
            /* if(this.state.isMistakeActive){
                objOrder.order_type = 'mistake';
            }else{
                objOrder.order_type = 'regular';
            } */
            this.setState({existing_order_id,selectedExistingOrder:this.props.order.Order,isShowLoader:false,...objState,objCustomer,customerFormData},()=>{
                this.calculateTotal(this.props.cart)
            });
        });
        
    }

    // Existing Tablet Modal
    onClickHandleLoadMoreExistingTabletNotificationOrderItems = () => {
        console.log('onClickHandleLoadMoreNewTabletNotificationOrderItems')
        this.setState({isloadingExistingTabletNotificationOrders:true});
        let skipTabletExistingNotificationOrders = this.state.skipTabletExistingNotificationOrders + this.state.limitTabletExistingNotificationOrders;
        this.props.fetchExistingTabletNotificationOrders(
                'tablet',
                'regular',
                'done',
                skipTabletExistingNotificationOrders,
                this.state.limitTabletExistingNotificationOrders,
                this.props.order.existingTabletNotificationOrders
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
                this.setState({skipTabletExistingNotificationOrders,isloadingExistingTabletNotificationOrders:false});
        });
    }
    clearExistingTabletNotificationOrderLimits = ()=>{
        this.setState({limitTabletExistingNotificationOrders:7,skipTabletExistingNotificationOrders:0});
    }
    handleOnChangeExistingTabletNotificationModalToggle  = (showExistingTabletOrderNotificationModal) =>{
        this.setState({showExistingTabletOrderNotificationModal:showExistingTabletOrderNotificationModal},
            () => {
              if(this.state.showExistingTabletOrderNotificationModal === false){
                  this.props.clearExistingTabletNotificationOrdersHistory();
                  this.clearExistingTabletNotificationOrderLimits();
              }else if(this.state.showExistingTabletOrderNotificationModal){
              }
        });
    }
    

    resetMenuScreen = () =>{
        this.props.clearMenuCategoryMenuItems();
        this.props.clearMenuItemComboItems();
        this.props.clearMenuItemIngredients();
        this.clearReceiptSelectedItems();
        this.setState({
            menuCategoryId:'',
            menuCategoryItemId: '',
            isShowMenuItemSizeCircles:false,
            isShowMenuItemSpecialModifiersCircles:false,
            selectedSpecialModifier:{},
            selectedSpecialModifierName:'',
            selectedSpecialModifierPrice:'',
            menuCategoryItem: {},
            selectedMenuItemIngredientTypeCategory: '',
            selectedMenuItemComboItemId: '',
            selectedItemType: '',
            /* selectedOnReceiptMenuItemId: '',
            selectedOnReceiptMenuItemTimeStamp: '',
            selectedOnReceiptItemType:'',
            selectedOnReceiptMenuItemComboItemId:'',
            */
            limitMenuItems:8000,
            limitMenuItemComboItems:8000,
            limitSauceIngredients:7000,
            limitMeatCheeseIngredients:7000,
            limitMiscIngredients:7000,
            // isRefundActive:false,
            lastSelectedItem:{},
            selectedMenuItem:{},
            selectedComboItem:{},
            selectedComboItemIngredient:{},

        })
    }
    clearReceiptSelectedItems = () =>{
        this.setState({
            selectedOnReceiptMenuItemId: '',
            selectedOnReceiptMenuItemTimeStamp: '',
            selectedOnReceiptItemType:'',
            selectedOnReceiptMenuItemComboItemId:'',
            selectedOnReceiptMenuItemIngredientId:'',
            selectedOnReceiptMenuItemIngredient:{},
            selectedOnReceiptMenuItemComboItemIngredientId:'',
            selectedOnReceiptMenuItemComboItemIngredient:{},
        });
    }
    clearDataOnMenuCategoryChange = () =>{
        this.props.clearMenuCategoryMenuItems();
        this.props.clearMenuItemComboItems();
        this.props.clearMenuItemIngredients();
        this.clearReceiptSelectedItems();
        this.setState({
            menuCategoryItemId: '',
            isShowMenuItemSizeCircles:false,
            isShowMenuItemSpecialModifiersCircles:false,
            selectedSpecialModifier:{},
            selectedSpecialModifierName:'',
            selectedSpecialModifierPrice:'',
            menuCategoryItem: {},
            selectedMenuItemIngredientTypeCategory: '',
            selectedMenuItemComboItemId: '',
            selectedItemType: '',
            loadingMenuItemComboItems: false,
            loadingMenuItemWithIngredients: false,
            /* selectedOnReceiptMenuItemId: '',
            selectedOnReceiptMenuItemTimeStamp: '',
            selectedOnReceiptItemType:'',
            selectedOnReceiptMenuItemComboItemId:'',
            selectedOnReceiptItemType:'', */
            limitMenuItems:8000,
            limitMenuItemComboItems:8000,
            limitSauceIngredients:7000,
            limitMeatCheeseIngredients:7000,
            limitMiscIngredients:7000,
        })
    }
    clearDataOnMenuItemChange = () =>{
        this.props.clearMenuItemComboItems();
        this.props.clearMenuItemIngredients();
        this.clearReceiptSelectedItems();
        this.setState({
            limitMenuItemComboItems:8000,
            limitSauceIngredients:7000,
            limitMeatCheeseIngredients:7000,
            limitMiscIngredients:7000,
        })
    }
    clearDataOnMenuItemComboItemChange = () =>{
        this.props.clearMenuItemIngredients();
        this.setState({
            limitSauceIngredients:7000,
            limitMeatCheeseIngredients:7000,
            limitMiscIngredients:7000,
        })   
    }
    clearDataOnMenuItemIngredientTypeChange = () =>{
        this.props.clearMenuItemIngredients();
        this.setState({
            limitSauceIngredients:7000,
            limitMeatCheeseIngredients:7000,
            limitMiscIngredients:7000,
        })
    }
    handleOnClickMenuCategory = (menuCategory) => {
        console.log('menuCategory')
        console.log(menuCategory)
        let menuCategoryId = menuCategory._id;
        this.clearDataOnMenuCategoryChange();
        this.setState({menuCategoryId,loadingMenuCatgoeryItems:true});
        this.props.getMenuItemsByCategoryId(menuCategoryId).then(response =>{
            // console.log('response in home fetchMenuCatgories')
            // console.log(response)
            this.setState({menuCategoryId,loadingMenuCatgoeryItems:false})
            
            // this.setState({loading:false})
        });

    }
    handleOnClickMenuCategoryItem = (menuItem) => {
        let menuCategoryItemId = menuItem._id;
        console.log('menuItem in handleOnClickMenuCategoryItem');
        console.log(menuItem)
        window.scrollTo({ top: 0, behavior: 'smooth' });
        this.clearDataOnMenuItemChange();
        this.setState({
            selectedItemType: 'menu_item',
            selectedMenuItemComboItemId: '',
            menuCategoryItemId,
            menuCategoryItem:menuItem,
            loadingMenuItemComboItems: true,
            loadingMenuItemWithIngredients: true,
            selectedMenuItemIngredientTypeCategory: 'no',
            // reseting selectedSpecialModifier 
            selectedSpecialModifier: {},
            selectedSpecialModifierName: '',
            selectedSpecialModifierPrice: '',
            isShowMenuItemSizeCircles:false,
            isShowMenuItemSpecialModifiersCircles:false,

        });
        if(menuItem.special_modifiers && menuItem.special_modifiers.length > 0 && menuItem.special_modifiers[0] && menuItem.special_modifiers[0].special_modifier_btn_one){
            // alert('special_modifiers')
            this.setState({isShowMenuItemSpecialModifiersCircles:true});
        }else if(menuItem.sizes && menuItem.sizes.length > 0){
            // alert('menuItem.sizes')
            this.setState({isShowMenuItemSizeCircles:true});
        }else{
            // alert('else')
            this.setState({isShowMenuItemSpecialModifiersCircles:false,isShowMenuItemSizeCircles:false});
            this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
                this.setState({loadingMenuItemComboItems:false})
            });
            this.props.fetchMenuItemByIdWithIngredients(menuCategoryItemId,'no').then(response => {
                this.setState({loadingMenuItemWithIngredients:false})
            });
           
            this.addToCartMenuItem(menuItem);
            
        }
    }
    handleOnClickMenuCategoryMenuItemSpecialModifier = (menuItem,SpecialModifier,special_modifier_name,special_modifier_price) => {
        let menuCategoryItemId = menuItem._id;
        // console.log('menuItem in handleOnClickMenuCategoryMenuItemSpecialModifier');
        // console.log('menuItem in handleOnClickMenuCategoryMenuItemSpecialModifier');
        // console.log('menuItem in handleOnClickMenuCategoryMenuItemSpecialModifier');
        // console.log(menuItem)
        // console.log(SpecialModifier)
        // this.clearDataOnMenuItemChange();
        this.setState({
            selectedItemType: 'menu_item',
            selectedMenuItemComboItemId: '',
            menuCategoryItemId,
            menuCategoryItem:menuItem,
            loadingMenuItemComboItems: true,
            loadingMenuItemWithIngredients: true,
            selectedMenuItemIngredientTypeCategory: 'no',
            isShowMenuItemSizeCircles:false,
            isShowMenuItemSpecialModifiersCircles:false,
        });
        if(menuItem.sizes && menuItem.sizes.length > 0){
            this.setState({
                isShowMenuItemSizeCircles: true,
                selectedSpecialModifier: SpecialModifier,
                selectedSpecialModifierName: special_modifier_name,
                selectedSpecialModifierPrice: special_modifier_price
            });
            // alert('i am in ser=tstae')
        }else{
            this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
                this.setState({loadingMenuItemComboItems:false})
            });
            this.props.fetchMenuItemByIdWithIngredients(menuCategoryItemId,'no').then(response => {
                this.setState({loadingMenuItemWithIngredients:false})
            });
            this.addToCartMenuItem(menuItem,false,false,{},'','',true,SpecialModifier,special_modifier_name,special_modifier_price);
        }
    }
    handleOnClickMenuCategoryMenuItemSize = (menuItem,size,size_name,size_price) => {
        let menuCategoryItemId = menuItem._id;
        // console.log('menuItem in handleOnClickMenuCategoryMenuItemSize');
        // console.log('menuItem in handleOnClickMenuCategoryMenuItemSize');
        // console.log('menuItem in handleOnClickMenuCategoryMenuItemSize');
        // console.log(menuItem)
        // console.log(size)
        // this.clearDataOnMenuItemChange();
        this.setState({
            selectedItemType: 'menu_item',
            selectedMenuItemComboItemId: '',
            menuCategoryItemId,
            menuCategoryItem:menuItem,
            loadingMenuItemComboItems: true,
            loadingMenuItemWithIngredients: true,
            selectedMenuItemIngredientTypeCategory: 'no',
            isShowMenuItemSizeCircles:false,
            isShowMenuItemSpecialModifiersCircles:false,
        });
        this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
            this.setState({loadingMenuItemComboItems:false})
        });
        this.props.fetchMenuItemByIdWithIngredients(menuCategoryItemId,'no').then(response => {
            this.setState({loadingMenuItemWithIngredients:false})
        });
        // alert(this.state.selectedSpecialModifierName)
        // alert(Object.keys(this.state.selectedSpecialModifier))
        if(this.state.selectedSpecialModifierName !=='' && Object.keys(this.state.selectedSpecialModifier).length > 0){
            this.addToCartMenuItem(menuItem,true,true,size,size_name,size_price,true,this.state.selectedSpecialModifier,this.state.selectedSpecialModifierName,this.state.selectedSpecialModifierPrice);
        }else{
            this.addToCartMenuItem(menuItem,false,true,size,size_name,size_price);
        }
    }
    addToCartMenuItem = (objMenuItem,isBothSelected = false,isSizeSelected = false,selectedSize = {},size_name = '',size_price = '',isSpecialModifierSelected = false,selectedisSpecialModifier = {},special_modifier_name = '',special_modifier_price = '') =>{
        // console.log('this.props.menuItemWithIngredient.menuItemWithIngredient')
        // console.log('this.props.menuItemWithIngredient.menuItemWithIngredient')
        // console.log(this.props.menuItemWithIngredient.menuItemWithIngredient)
        // console.log('this.props.menuItemWithIngredient.menuItemWithIngredient')
        // console.log('this.props.menuItemWithIngredient.menuItemWithIngredient')
        // console.log('this.props.menuItemWithIngredient.menuItemWithIngredient')
        // console.log(this.props.menuItemWithIngredient.menuItemWithIngredient)

        let product = {};
        product.menu_item_id = objMenuItem._id;
        product.name = objMenuItem.name;
        product.itemQty = 1;
        if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            product.price = 0;
            product.totalPrice = 0;
            product.mistake_price = objMenuItem.price;
        }else{
            if(isBothSelected){
                // alert(size_price)
                // alert(special_modifier_price)
                if(special_modifier_price !== '' && isNaN(special_modifier_price) === false){
                    product.price =  (parseFloat(size_price) +  parseFloat(special_modifier_price)).toFixed(2);
                    product.totalPrice = (parseFloat(size_price) +  parseFloat(special_modifier_price)).toFixed(2);
                }else{
                    product.price = size_price;
                    product.totalPrice = size_price;    
                }
                product.selected_size = selectedSize;
                product.size_name = size_name;
                product.size_price = size_price;
                product.selected_special_modifier = selectedisSpecialModifier;
                product.special_modifier_name = special_modifier_name;
                product.special_modifier_price = special_modifier_price;
            }else if(isSizeSelected){
                product.price = size_price;
                product.totalPrice = size_price;
                product.selected_size = selectedSize;
                product.size_name = size_name;
                product.size_price = size_price;
            }else if(isSpecialModifierSelected){
                // The isNaN() function returns true if the value is NaN (Not-a-Number), and false if not 
                if(special_modifier_price !== '' && isNaN(special_modifier_price) === false){
                    product.price =  (parseFloat(objMenuItem.price) +  parseFloat(special_modifier_price)).toFixed(2);
                    product.totalPrice = (parseFloat(objMenuItem.price) +  parseFloat(special_modifier_price)).toFixed(2);
                }else{
                    product.price = objMenuItem.price;
                    product.totalPrice = objMenuItem.price;
                }
                product.selected_special_modifier = selectedisSpecialModifier;
                product.special_modifier_name = special_modifier_name;
                product.special_modifier_price = special_modifier_price;
            }else{
                product.price = objMenuItem.price;
                product.totalPrice = objMenuItem.price;
            }
        }
        product.is_size_selected = isSizeSelected;
        product.is_special_modifier_selected = isSpecialModifierSelected;
        product.menuCategoryId = this.state.menuCategoryId;
        product.selectedComboItems = [];
        product.ingredients = [];
        product.timestamp = getUniqueNameWithTimeStamp();
        // console.log('product')
        // console.log(product)
        // let { a, b, ...rest } = { a: 10, b: 20, c: 30, d: 40 };
        // console.log(rest); // { c: 30, d: 40 }
        let { comboItems, sizes, special_modifiers, menu_items_combos_order, menu_item_ingredient_no_order,menu_item_ingredient_extra_order,menu_item_ingredient_add_order,menu_item_ingredient_light_order,menu_item_ingredient_only_order,menu_item_ingredient_well_done_order,menu_item_ingredient_put_on_side_order, ...objMenuItemOptimized } = objMenuItem;
        product.objMenuItem = objMenuItemOptimized;
        // product.objMenuItem = objMenuItem;
        product.MenuItemsIngredientsMultiple = [];
        product.ingredientTotal = 0;
        product.item_type = 'menu_item';
        // console.log('product before adding into cart')
        // console.log('product before adding into cart')
        // console.log('product before adding into cart')
        // console.log(product)
        let lastSelectedItem = {item_type:'menu_item',menu_item:product};
        this.setState({selected_menu_item_timestamp:product.timestamp,lastSelectedItem},()=>{
            if(this.state.isRefundActive){
                this.onClickAddRefundedItem();
            }
        });
        if(!this.state.isRefundActive){
            this.props.addToCart(product).then(response => {
                this.calculateTotal(this.props.cart);
            });
            this.props.fetchMenuItemByIdWithIngredients(objMenuItem._id,'no').then(response => {
                const menuItemWithIngredient = this.props.menuItemWithIngredient.menuItemWithIngredient;
                // console.log('menuItemWithIngredient')
                // console.log('menuItemWithIngredient')
                // console.log('menuItemWithIngredient')
                // console.log(menuItemWithIngredient)
                if(Object.keys(menuItemWithIngredient).length > 0){
                    const arrIngredients = menuItemWithIngredient.Ingredients; 
                    // for (let index = 0; index < arrIngredients.length; index++) {
                    //     const ingredient = arrIngredients[index];
                    //     // console.log('ingredient')
                    //     // console.log('ingredient')
                    //     // console.log(ingredient)
                    //     this.addIngredientToMenuItemCart(ingredient,ingredient.ingredient_main_type,false,true);
                    // }
                    this.props.addIncludedAndBothIngredientsToMenuItemCart(product,arrIngredients)
                }
            });
        }
    }
    onClickAddRefundedItem = () =>{
        if(this.state.isRefundActive){
            let lastSelectedItem = this.state.lastSelectedItem;
            const timestamp = getUniqueNameWithTimeStamp();
            console.log('lastSelectedItem in handleOnClickDoneOrder')
            console.log(lastSelectedItem)
            if(lastSelectedItem.item_type === "menu_item"){
                this.props.addRefundedItemToCart(lastSelectedItem,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });;
                // We will have to create a function in store 
            }else if(lastSelectedItem.item_type === "combo_item"){
                let comboItem = 
                {   
                    item_type : lastSelectedItem.item_type,
                    menu_item_id : lastSelectedItem.menu_item_id,
                    menu_item_id_time_stamp : lastSelectedItem.menu_item_id_time_stamp,
                    price : lastSelectedItem.selectedComboItem.combo_item_price,
                    totalPrice:lastSelectedItem.selectedComboItem.combo_item_price,
                    menuCategoryId:this.state.menuCategoryId,
                    // is_checked : true,
                    // menu_item_id : menu_item_id,
                    // notes,
                    is_note : false,
                    timestamp,
                    ...lastSelectedItem.selectedComboItem,
                }
                
                
                this.props.addRefundedItemToCart(comboItem,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }else if(lastSelectedItem.item_type === "menu_item_ingredient"){
                
                let menu_item_ingredient = 
                {   
                    item_type : lastSelectedItem.item_type,
                    menu_item_id : lastSelectedItem.menu_item_id,
                    menu_item_id_time_stamp : lastSelectedItem.menu_item_id_time_stamp,
                    ...lastSelectedItem.ingredient,
                    menuCategoryId:this.state.menuCategoryId,
                    // is_checked : true,
                    // menu_item_id : menu_item_id,
                    // notes,
                    is_note : false,
                    timestamp,
                }
                console.log('lastSelectedItem in menu_item_ingredient')
                console.log(lastSelectedItem)
                console.log('lastSelectedItem.ingredientType')
                console.log(lastSelectedItem.ingredientType)
                if((lastSelectedItem.ingredient.ingredientType === 'extras_charged') || (lastSelectedItem.ingredient.ingredientType === 'both')){
                    console.log('i am in if')
                    menu_item_ingredient.totalPrice = lastSelectedItem.ingredient.price;
                }else{
                    console.log('i am in else')
                    menu_item_ingredient.totalPrice = 0;
                }
                console.log('menu_item_ingredient in menu_item_ingredient')
                console.log(menu_item_ingredient)
                this.props.addRefundedItemToCart(menu_item_ingredient,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }else if(lastSelectedItem.item_type === "combo_item_ingredient"){
                
                let combo_item_ingredient = 
                {   
                    item_type : lastSelectedItem.item_type,
                    menu_item_id : lastSelectedItem.menu_item_id,
                    menu_item_id_time_stamp : lastSelectedItem.menu_item_id_time_stamp,
                    menuCategoryId:this.state.menuCategoryId,
                    // totalPrice:lastSelectedItem.ingredient.price,
                    ...lastSelectedItem.ingredient,
                    // is_checked : true,
                    // menu_item_id : menu_item_id,
                    // notes,
                    is_note : false,
                    timestamp,
                }
                console.log('lastSelectedItem in combo_item_ingredient')
                console.log(lastSelectedItem)
                if((lastSelectedItem.ingredient.ingredientType === 'extras_charged') || (lastSelectedItem.ingredient.ingredientType === 'both')){
                    combo_item_ingredient.totalPrice = lastSelectedItem.ingredient.price;
                }else{
                    combo_item_ingredient.totalPrice = 0;
                }
                console.log('combo_item_ingredient in combo_item_ingredient')
                console.log(combo_item_ingredient)
                this.props.addRefundedItemToCart(combo_item_ingredient,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }
            // this.handelOnClickRefundMenu();
        }
    }
    handleOnClickMenuItemComboItem = (comboItem) => {
        let menuItemComboItemId = comboItem._id;
        console.log('comboItem')
        console.log(comboItem)
        this.clearDataOnMenuItemComboItemChange();
        this.setState({selectedItemType:'combo_item',selectedMenuItemComboItemId:menuItemComboItemId,loadingMenuItemWithIngredients:true,selectedMenuItemIngredientTypeCategory:'no'});
        this.props.fetchMenuItemByIdWithIngredients(menuItemComboItemId,'no').then(response => {
            this.setState({loadingMenuItemWithIngredients:false})
            
        });
        const menu_item_id = this.state.menuCategoryItemId;
        const menu_item_id_time_stamp = this.state.selected_menu_item_timestamp;
        let isExist = false;
        let cart = this.props.cart;
        const cartItemIndex = cart.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === menu_item_id_time_stamp);
        // console.log('cartItemIndex')
        // console.log(cartItemIndex)
        if (cartItemIndex !== -1 && cartItemIndex !== '-1') {
            const isComboItemExist = cart[cartItemIndex].selectedComboItems.findIndex(combo_item => combo_item.selectedComboItemId === comboItem._id );
            // console.log('isComboItemExist')
            // console.log(isComboItemExist)
            if(isComboItemExist !== -1 && isComboItemExist !== '-1'){
                isExist = true;
            }
        }
        if(!isExist){
            let selectedComboItem = {}
            selectedComboItem.selectedComboItemId = comboItem._id;
            selectedComboItem._id = comboItem._id;
            selectedComboItem.name = comboItem.name;
            selectedComboItem.ingredientTotal = 0;
            selectedComboItem.combo_item_price = comboItem.combo_item_price;
            selectedComboItem.ingredients = [];
            if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
                selectedComboItem.combo_item_price = 0;
                selectedComboItem.mistake_combo_item_price = comboItem.combo_item_price;
            }
            let lastSelectedItem = {item_type:'combo_item',menu_item_id,menu_item_id_time_stamp,selectedComboItem};
            this.setState({lastSelectedItem},()=>{
                if(this.state.isRefundActive){
                    this.onClickAddRefundedItem();
                }
            });
            if(!this.state.isRefundActive){
                this.props.addComboItemToMenuItemCart(menu_item_id,menu_item_id_time_stamp,selectedComboItem).then(response => {
                    this.calculateTotal(this.props.cart);
                    this.props.fetchMenuItemByIdWithIngredients(menuItemComboItemId,'no').then(response => {
                        this.setState({loadingMenuItemWithIngredients:false})
                        const menuItemWithIngredient = this.props.menuItemWithIngredient.menuItemWithIngredient;
                        // console.log('menuItemWithIngredient')
                        // console.log(menuItemWithIngredient)
                        if(Object.keys(menuItemWithIngredient).length > 0){
                            const arrIngredients = menuItemWithIngredient.Ingredients; 
                            
                            this.props.addIncludedAndBothIngredientsToComboMenuItemCart(menu_item_id,menu_item_id_time_stamp,selectedComboItem,arrIngredients)
                        }
                    });
                });
            }
        }
    }

    

    handleOnClickChangeMenuItemIngredientType = (ingredient_type) => {
        // this.setState({selectedOrderType:order_type});
        // console.log('this.state.selectedItemType')
        // console.log(this.state.selectedItemType)
        this.clearDataOnMenuItemIngredientTypeChange();
        let item_id = '';
        if(this.state.selectedItemType === 'menu_item'){
            item_id = this.state.menuCategoryItemId;
        }else if(this.state.selectedItemType === 'combo_item'){
            item_id = this.state.selectedMenuItemComboItemId;
        }
        this.setState({
            loadingMenuItemWithIngredients: true,
            selectedMenuItemIngredientTypeCategory: ingredient_type,
            limitSauceIngredients:7000,
            limitMeatCheeseIngredients:7000,
            limitMiscIngredients:7000,
        });
        this.props.fetchMenuItemByIdWithIngredients(item_id,ingredient_type).then(response => {
            this.setState({loadingMenuItemWithIngredients:false})
        });
    }



    loadMoreMenuItems = () => {
        this.setState({
            limitMenuItems: this.state.limitMenuItems + 8000
        });        
    }
    loadMoreMenuItemComboItems = () => {
        this.setState({
            limitMenuItemComboItems: this.state.limitMenuItemComboItems + 8000
        });        
    }

    loadMoreSauceIngredients = () => {
        this.setState({
            limitSauceIngredients: this.state.limitSauceIngredients + 7000
        });        
    }
    
    loadMoreMeatCheeseIngredients = () => {
        this.setState({
            limitMeatCheeseIngredients: this.state.limitMeatCheeseIngredients + 7000
        });        
    }

    loadMoreMiscIngredients = () => {
        this.setState({
            limitMiscIngredients: this.state.limitMiscIngredients + 7000
        });        
    }
    handleOnClickFoodCartRemoveIcon = () => {
        if(this.state.selectedOnReceiptItemType === 'menu_item'){
            this.removeFromCart(this.state.selectedMenuItem,this.state.selectedOnReceiptItemType);
        }else if(this.state.selectedOnReceiptItemType === 'menu_item_ingredient'){
            this.removeIngredientFromCartMenuItem(this.state.selectedMenuItem,this.state.selectedOnReceiptMenuItemIngredient);
        }else if(this.state.selectedOnReceiptItemType === 'combo_item'){
            this.removeSubItemFromCart(this.state.selectedMenuItem,this.state.selectedComboItem);
        }else if(this.state.selectedOnReceiptItemType === 'combo_item_ingredient'){
            this.removeIngredientFromCartComboItem(this.state.selectedMenuItem,this.state.selectedComboItem,this.state.selectedComboItemIngredient);
        }else if(this.state.selectedOnReceiptItemType === 'menu_item_notes'){
            this.removeFromCart(this.state.selectedMenuItem,'notes');
        }else if(this.state.selectedOnReceiptItemType === 'refunded_item'){
            this.removeFromCart(this.state.selectedMenuItem,'refunded_item');
        }else if(this.state.selectedOnReceiptItemType === 'menu_item_ingredient_notes'){
            this.removeIngredientFromCartMenuItem(this.state.selectedMenuItem,this.state.selectedOnReceiptMenuItemIngredient);
        }else if(this.state.selectedOnReceiptItemType === 'combo_item_ingredient_notes'){
            this.removeIngredientFromCartComboItem(this.state.selectedMenuItem,this.state.selectedComboItem,this.state.selectedComboItemIngredient);
        }

    }

    removeFromCart = (product,type) => {
        // console.log('product in removeCart')
        // console.log(product);
        if(type === 'menu_item'){
            this.props.removeCartItem(product).then(response => {
                this.resetMenuScreen();
                this.calculateTotal(this.props.cart);
            });
        }else{
            this.props.removeNotesFromCart(product).then(response => {
                this.resetMenuScreen();
                this.calculateTotal(this.props.cart);
            });
        }
    }
    removeIngredientFromCartMenuItem = (menuItem,ingredient) => {
        // console.log('menuItem in removeCart')
        // console.log(menuItem);
        // console.log('ingredient');
        // console.log(ingredient);
        this.props.removeIngredientFromCartMenuItem(menuItem,ingredient).then(response => {
            this.resetMenuScreen();
            this.calculateTotal(this.props.cart);
        });
    }
    removeSubItemFromCart = (product,subProduct) => {
        // console.log('product in removeSubItemFromCart')
        // console.log(product);
        // console.log('subProduct in removeSubItemFromCart')
        // console.log(subProduct);
        this.props.removeCartSubItem(product,subProduct).then(response => {
            // console.log('this.props.objtax in resp');
            // console.log(this.props.objtax);
            this.resetMenuScreen();
            this.calculateTotal(this.props.cart);
            // console.log(this.props.objtax);
        });
    }
    removeIngredientFromCartComboItem = (menuItem,comboItem,ingredient) => {
        // console.log('menuItem in removeCart')
        // console.log(menuItem);
        // console.log('ingredient');
        // console.log(ingredient);
        this.props.removeIngredientFromCartComboItem(menuItem,comboItem,ingredient).then(response => {
            this.resetMenuScreen();
            this.calculateTotal(this.props.cart);
        });
    }
    addIngredientToMenuItemCart = (ingredient,ingredient_type_main,is_checked = true,isOriginalIncludedBothIngredient = false) => {
        // console.log('ingredient in addIngredientToMenuItemCart')
        // console.log(ingredient)
        if(ingredient.ingredientsMenuItems){
            delete ingredient['ingredientsMenuItems']; 
        }
        ingredient.added_from = 'pos';
        // ingredient.isHide = isHide;
        ingredient.isOriginalIncludedBothIngredient = isOriginalIncludedBothIngredient;
        let item_id = '';
        if(this.state.selectedItemType === 'menu_item'){
            item_id = this.state.menuCategoryItemId;
            const menu_item_ingredient_timestamp = getUniqueNameWithTimeStamp();
            let ingredient1 = 
            {   ...ingredient,  
                ingredient_type_main : ingredient_type_main,
                ingredient_type : ingredient.ingredientType,
                is_checked,
                // is_checked : true,
                is_note : false,
                menu_item_id : item_id,
                menu_item_ingredient_timestamp,
                // menuitemid : item_id,
                ingredient_category_type_blue : this.state.selectedMenuItemIngredientTypeCategory,
            }
            // console.log('ingredient1')
            // console.log(ingredient1)
            let isExist = false;
            let cart = this.props.cart;
            const cartItemIndex = cart.findIndex(item => item.menu_item_id === item_id &&  item.timestamp === this.state.selected_menu_item_timestamp);
            if (cartItemIndex !== -1 && cartItemIndex !== '-1') {
                const isIngredientExist = cart[cartItemIndex].ingredients.findIndex(ingredient => ingredient._id === ingredient1._id && ingredient.ingredient_category_type_blue === this.state.selectedMenuItemIngredientTypeCategory);
                if(isIngredientExist !== -1 && isIngredientExist !== '-1'){
                    isExist = true;
                }
            }
            // because we are now allowing multiple Ingredients
            isExist = false;
            if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
                if (this.state.selectedMenuItemIngredientTypeCategory === 'no' || this.state.selectedMenuItemIngredientTypeCategory === 'light' || this.state.selectedMenuItemIngredientTypeCategory === 'put_on_side'){
                    ingredient1.no_price = ingredient1.price;
                    ingredient1.put_on_side_price = ingredient1.price;
                    ingredient1.mistake_price = 0;
                }else if(this.state.selectedMenuItemIngredientTypeCategory === 'only' && ingredient.ingredientType !== 'extras_charged'){
                    ingredient1.only_price = ingredient1.price;
                    ingredient1.mistake_price = 0;
                }else{
                    ingredient1.mistake_price = ingredient1.price;
                }
                // ingredient1.mistake_price = ingredient1.price;
                ingredient1.price = 0;
            } else if (this.state.selectedMenuItemIngredientTypeCategory === 'no' || this.state.selectedMenuItemIngredientTypeCategory === 'light' || this.state.selectedMenuItemIngredientTypeCategory === 'put_on_side'){
                ingredient1.no_price = ingredient1.price;
                ingredient1.light_price = ingredient1.price;
                ingredient1.put_on_side_price = ingredient1.price;
                ingredient1.price = 0;
            }else if(this.state.selectedMenuItemIngredientTypeCategory === 'only' && ingredient.ingredientType !== 'extras_charged'){
                ingredient1.only_price = ingredient1.price;
                ingredient1.price = 0;
            }
            
            let lastSelectedItem = {item_type:'menu_item_ingredient',menu_item_id : item_id,menu_item_timestamp:this.state.selected_menu_item_timestamp,ingredient:ingredient1,ingredient_type_main,selectedMenuItemIngredientTypeCategory:this.state.selectedMenuItemIngredientTypeCategory,selectedOnReceiptItemType:this.state.selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredient:this.state.selectedOnReceiptMenuItemIngredient};
            this.setState({lastSelectedItem},()=>{
                if(this.state.isRefundActive){
                    this.onClickAddRefundedItem();
                }
            });
            if(!this.state.isRefundActive){
                if(!isExist){
                    this.props.addIngredientToMenuItemCartA(item_id, this.state.selected_menu_item_timestamp, ingredient1, ingredient_type_main, this.state.selectedMenuItemIngredientTypeCategory,this.state.selectedOnReceiptItemType,this.state.selectedOnReceiptMenuItemIngredient).then(response => {
                        this.calculateTotal(this.props.cart);
                    });
                }
            }
        }else if(this.state.selectedItemType === 'combo_item'){
            console.log('i am in combo_item')
            const menu_item_id = this.state.menuCategoryItemId;
            const combo_menu_item_id = this.state.selectedMenuItemComboItemId;
            const combo_item_ingredient_timestamp = getUniqueNameWithTimeStamp();
            let ingredient1 = 
            {   ...ingredient,  
                ingredient_type_main : ingredient_type_main,
                ingredient_type : ingredient.ingredientType,
                is_checked,
                // is_checked : true,
                is_note : false,
                menu_item_id : menu_item_id,
                combo_menu_item_id,
                combo_item_ingredient_timestamp,
                // menuitemid : item_id,
                ingredient_category_type_blue : this.state.selectedMenuItemIngredientTypeCategory,
            }
            let isExist = false;
            let cart = this.props.cart;
            const cartItemIndex = cart.findIndex(item => item.menu_item_id === menu_item_id &&  item.timestamp === this.state.selected_menu_item_timestamp);
            if (cartItemIndex !== -1 && cartItemIndex !== '-1') {
                const comboItemIndex = cart[cartItemIndex].selectedComboItems.findIndex(cmitem => cmitem.selectedComboItemId === combo_menu_item_id );

                if(comboItemIndex !== -1 && comboItemIndex !== '-1'){
                    // isExist = true;
                    const isIngredientExist = cart[cartItemIndex].selectedComboItems[comboItemIndex].ingredients.findIndex(ingredient => ingredient._id === ingredient1._id && ingredient.ingredient_category_type_blue === this.state.selectedMenuItemIngredientTypeCategory);
                    if(isIngredientExist !== -1 && isIngredientExist !== '-1'){
                        isExist = true;
                    }
                }
            }
            // because we are now allowing multiple Ingredients
            isExist = false;
            if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
                if (this.state.selectedMenuItemIngredientTypeCategory === 'no' || this.state.selectedMenuItemIngredientTypeCategory === 'light' || this.state.selectedMenuItemIngredientTypeCategory === 'put_on_side'){
                    ingredient1.no_price = ingredient1.price;
                    ingredient1.put_on_side_price = ingredient1.price;
                    ingredient1.mistake_price = 0;
                }else if(this.state.selectedMenuItemIngredientTypeCategory === 'only' && ingredient.ingredientType !== 'extras_charged'){
                    ingredient1.only_price = ingredient1.price;
                    ingredient1.mistake_price = 0;
                }else{
                    ingredient1.mistake_price = ingredient1.price;
                }
                // ingredient1.mistake_price = ingredient1.price;
                ingredient1.price = 0;
            } else if (this.state.selectedMenuItemIngredientTypeCategory === 'no' || this.state.selectedMenuItemIngredientTypeCategory === 'light' || this.state.selectedMenuItemIngredientTypeCategory === 'put_on_side'){
                ingredient1.no_price = ingredient1.price;
                ingredient1.light_price = ingredient1.price;
                ingredient1.put_on_side_price = ingredient1.price;
                ingredient1.price = 0;
            }else if(this.state.selectedMenuItemIngredientTypeCategory === 'only' && ingredient.ingredientType !== 'extras_charged'){
                ingredient1.only_price = ingredient1.price;
                ingredient1.price = 0;
            }
            // console.log('isExist in comboItem')
            // console.log(isExist)
            // console.log('ingredient1')
            // console.log(ingredient1)
            let lastSelectedItem = {item_type:'combo_item_ingredient',menu_item_id : menu_item_id,menu_item_timestamp:this.state.selected_menu_item_timestamp,combo_menu_item_id,ingredient:ingredient1,ingredient_type_main,selectedMenuItemIngredientTypeCategory:this.state.selectedMenuItemIngredientTypeCategory,selectedOnReceiptItemType:this.state.selectedOnReceiptItemType,selectedOnReceiptMenuItemComboItemIngredient:this.state.selectedOnReceiptMenuItemComboItemIngredient};
            this.setState({lastSelectedItem},()=>{
                if(this.state.isRefundActive){
                    this.onClickAddRefundedItem();
                }
            });
            if(!this.state.isRefundActive){
                if(!isExist){
                    this.props.addIngredientToMenuItemComboItemCart(menu_item_id, this.state.selected_menu_item_timestamp,combo_menu_item_id, ingredient1, ingredient_type_main, this.state.selectedMenuItemIngredientTypeCategory,this.state.selectedOnReceiptItemType,this.state.selectedOnReceiptMenuItemComboItemIngredient).then(response => {
                        this.calculateTotal(this.props.cart);
                    });
                }
            }
        }
        
    }

    calculateTotal = (cartDetail) => {
        let subTotal = 0;
        let refundItemsTotal = 0;
        let simpleItemsTotal = 0;

        cartDetail.forEach((item,index)=>{
            if(item.item_type === "refunded_item"){
                refundItemsTotal = parseFloat(parseFloat(refundItemsTotal) + parseFloat(item.totalPrice)).toFixed(4);
                
            }else{
                simpleItemsTotal = parseFloat(parseFloat(simpleItemsTotal) + parseFloat(item.totalPrice)).toFixed(4);
            }
            
        });
        // if(this.state.selectedOrderType === 'delivery'){
        //     simpleItemsTotal = parseFloat(parseFloat(simpleItemsTotal) + parseFloat(this.state.delivery_amount)).toFixed(4)
        // }
        
        let discount_percentage = this.state.discount_percentage;
        let discount_amount_entered = this.state.discount_amount_entered;
        let discount_amount = 0;
        // console.log('discount_percentage')
        // console.log(discount_percentage)
        // console.log('discount_amount_entered')
        // console.log(discount_amount_entered)
        // console.log('simpleItemsTotal')
        // console.log(simpleItemsTotal)
        // console.log(typeof simpleItemsTotal)
        console.log('refundItemsTotal')
        console.log(refundItemsTotal)
        // console.log(typeof refundItemsTotal)
        // console.log(simpleItemsTotal >= refundItemsTotal)
        // console.log(`${simpleItemsTotal} >= ${refundItemsTotal}`)
        // we are not applying discount to the order if their are refunded items but if you want to apply discount on the refunded orders to then add parsefloat with simpleitemstotala and refunditemstotal
        // if(simpleItemsTotal >= refundItemsTotal && refundItemsTotal === 0){
        if(parseFloat(simpleItemsTotal) >= parseFloat(refundItemsTotal) && parseFloat(refundItemsTotal) === 0){
                // console.log('I am in simpleItemsTotal >= refundItemsTotal')
            // console.log('I am in simpleItemsTotal >= refundItemsTotal')
            // console.log('I am in simpleItemsTotal >= refundItemsTotal')
            discount_amount = ((discount_percentage * simpleItemsTotal)/100).toFixed(4);
            if(discount_amount_entered !== ''){
                discount_amount = parseFloat(parseFloat(discount_amount) + parseFloat(discount_amount_entered)).toFixed(4)
            }
        }else{
            discount_amount = 0;
        }
        // console.log('subTotal before calculateTotal')
        // console.log('subTotal before calculateTotal')
        // console.log(subTotal)
        // subTotal = (parseFloat(simpleItemsTotal)-parseFloat(refundItemsTotal)).toFixed(4); 
        subTotal = (parseFloat(simpleItemsTotal)-parseFloat(refundItemsTotal)-parseFloat(discount_amount)).toFixed(4); 
        // console.log('subTotal in start calculateTotal')
        // console.log('subTotal in start calculateTotal')
        // console.log(subTotal)
        // Start Finding the tax
        let restaurant_province = this.state.restaurant_province;
        let tax_per = 0;
        // if(restaurant_province.toLowerCase() === 'ontario'){
        //     if(parseFloat(subTotal) <= 4){
        //         tax_per = 5; 
        //     }else if(parseFloat(subTotal) > 4){
        //         tax_per = 13; 
        //     }
        // }
        // End Finding the tax
        let tax = parseFloat(tax_per/100).toFixed(4);
        // let tax = parseFloat(this.state.tax/100).toFixed(4);
        // let taxAmount = ((parseFloat(subTotal)-parseFloat(discount_amount)) * (parseFloat(tax))).toFixed(4);
        let taxAmount = (parseFloat(subTotal) * parseFloat(tax)).toFixed(4);
        // let total = (parseFloat(subTotal) + parseFloat(taxAmount)-parseFloat(discount_amount)).toFixed(4);
        let total = (parseFloat(subTotal) + parseFloat(taxAmount)).toFixed(4);
        // console.log('subTotal in end calculateTotal')
        // console.log('subTotal in end calculateTotal')
        // console.log(subTotal)
        let objState = {
            subTotal,
            total,
            cartDetail,
            discount_amount,
            taxAmount,
            simpleItemsTotal,
            refundItemsTotal,
            tax:tax_per,
            // showTotal: true
        };
        if(discount_amount === 0){
            objState.discount_percentage = 0;
            objState.discount_amount_entered = 0;
        }
        this.setState(objState);
    }
    calculateTotalAmount = () => {
        let total = parseFloat(this.state.total).toFixed(4);
        let tax = parseFloat(this.state.tax/100).toFixed(4);
        let taxAmount = ((total) * (tax)).toFixed(4);
        return (parseFloat(total) + parseFloat(taxAmount)).toFixed(4);
    }
    calculateChangeAmount = () => {
        let changeAmount = parseFloat(this.state.changeAmount).toFixed(2);
        let customerGivenAmount = parseFloat(this.state.customerGivenAmount).toFixed(2);
        if(isNaN(customerGivenAmount)){
            customerGivenAmount = 0;
        }
        let total = parseFloat(this.state.total).toFixed(2);
        // console.log('total')
        // console.log(total)
        // console.log('customerGivenAmount')
        // console.log(customerGivenAmount)
        if(total < 0 ){
            changeAmount = -(parseFloat(customerGivenAmount) - parseFloat(total)).toFixed(2);
        }else if(customerGivenAmount === '' || customerGivenAmount === 0){
            changeAmount = 0.00;
        }else{
            changeAmount = (parseFloat(customerGivenAmount) - parseFloat(total)).toFixed(2);
        }
        this.setState({changeAmount})
        return changeAmount;
    }

    handleOnClickVoidMenu = (isResetCustomerGivenAmount = true,isMenuClicked=false) => {
        let isVoidScreen = true;
        if(this.state.existing_order_id !== '' && isMenuClicked){
            let conf = window.confirm("Are you sure you want to cancel this order completely?");
            if(conf){
                isVoidScreen = true;
                let existing_order_id = this.state.existing_order_id;
                let selectedExistingOrder = this.state.selectedExistingOrder;

                this.props.deleteOrderByOId(this.state.existing_order_id,selectedExistingOrder).then(response => {
                    showMsg("Order has been deleted successfully.",'success');
                    socket.emit("deleteKitchenOrder", existing_order_id);
                    if(selectedExistingOrder.created_from === 'web'){
                        socket.emit("OrderWebNotificationsCountDecrease",1);
                    }else if(selectedExistingOrder.created_from === 'tablet'){
                        socket.emit("TabletOrderNotificationsCountDecrease",1);
                    }
                });
            }else{
                isVoidScreen = false;
            }
        }
        if(isVoidScreen){
            this.resetMenuScreen();
            let objState = {
                isRefundActive:false,
                // selectedOrderType: '',
                selectedOrderType: 'takeout',
                query: '',
                objCustomer: {},
                city: '',
                // delivery_type:'',
                delivery_type:'takeout',
                isCustomerSavingLoader:false,
                customerFormData : {},
                selectedPhoneNumber:{},
                // tax : 0,
                // discount_amount:0,
                // discount_percentage:0,
                delivery_amount:0,
                distance_in_kilo:'',
                time_picker_time:'',
                value: '', 
                phone_no: '', 
                suggestions: [],
                discount_amount : 0,
                discount_percentage : 0,
                discount_amount_entered : 0,
                taxAmount : 0,
                totalAmountAfterTax : 0,
                refundItemsTotal : 0,
                simpleItemsTotal : 0,
                isMistakeActive:false,
                isOrderNoKitchen: false,
                mistake_type:'',
                existing_order_id:'',
                selectedExistingOrder:{},
                drp_default_time:'',
                timeSelectionRadioBox:'',
                taxAmount:0,
                subTotal:0.00,
                total:0.0,
                totalAmount:0,
                selectedDeliveryPaymentMethod:'',
                // card_processing_fee:false,
            }
            if(isResetCustomerGivenAmount){
                objState.customerGivenAmount ='';
                objState.result = '';
            }
            this.setState(objState)
            this.props.clearCart().then(response => {
                this.calculateTotal(this.props.cart);
            });
        }
        
    }
    handleOnClickDiscountMenu = () => {
        console.log('handleOnClickDiscountMenu');
        // if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
        if(this.state.isMistakeActive){
                showMsg("Disocunt Can not be added for the Miskate Order.",'error');
        }else{
            this.setState({showDiscountModal:true});
        }
    }
    handleOnChangeDiscountModalToggle = (showDiscountModal,isFormValidated,discount_percentage,discount_amount_entered) =>{
        if(isFormValidated){
            // alert('discount_percentage')
            // alert(discount_percentage)
            this.setState({showDiscountModal,discount_percentage,discount_amount_entered}, () => {
                this.calculateTotal(this.props.cart);
            });
        }else{
            this.setState({showDiscountModal});
        }
    }

    handleOnClickSelectReceiptMenuItem = (menuItem,type = 'menu_item') => {
        console.log('handleOnClickSelectReceiptMenuItem')
        console.log(menuItem)
        this.resetMenuScreen();
        if(type === 'menu_item'){
            // Menu Cateory
            let menuCategoryId = menuItem.menuCategoryId;
            let menuCategoryItemId = menuItem.menu_item_id;
            this.setState({
                isShowLoader:true,
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                menuCategoryId,
                selected_menu_item_timestamp:menuItem.timestamp,
                loadingMenuCatgoeryItems: true,
                selectedOnReceiptItemType:'menu_item',
                selectedItemType: 'menu_item',
                selectedMenuItemComboItemId: '',
                menuCategoryItemId,
                loadingMenuItemComboItems: true,
                loadingMenuItemWithIngredients: true,
                selectedMenuItemIngredientTypeCategory: 'no',
                selectedMenuItem:menuItem,
            });
            this.props.getMenuItemsByCategoryId(menuCategoryId).then(response =>{
                this.setState({loadingMenuCatgoeryItems:false})
            });
            // Menu Item 
            console.log('menuCategoryItemId')
            console.log(menuCategoryItemId)
            
            this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
                this.setState({loadingMenuItemComboItems:false})
            });
            this.props.fetchMenuItemByIdWithIngredients(menuCategoryItemId,'no').then(response => {
                this.setState({loadingMenuItemWithIngredients:false,isShowLoader:false})
            });
            /* this.props.clearCart().then(response => {
                this.calculateTotal(this.props.cart);
            }); */

        }else if(type === 'notes'){
            this.setState({
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptItemType:'menu_item_notes',
                selectedItemType: 'menu_item',
                selectedMenuItem:menuItem,
            });
            
        }else if(type === 'refunded_item'){
            this.setState({
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptItemType:'refunded_item',
                selectedItemType: 'menu_item',
                selectedMenuItem:menuItem,
            });
            
        }
    }
    handleOnClickSelectReceiptMenuItemIngredient = (menuItem,ingredient,type='menu_item_ingredient') =>{
        console.log('handleOnClickSelectReceiptMenuItemIngredient')
        console.log(menuItem)
        console.log('ingredient')
        console.log(ingredient)

        this.resetMenuScreen();
        let menuCategoryId = menuItem.menuCategoryId;
        let menuCategoryItemId = menuItem.menu_item_id;
        if(type === 'menu_item_ingredient'){
            // Menu Cateory
            this.setState({
                isShowLoader:true,
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selectedOnReceiptMenuItemIngredientId: ingredient._id,
                selectedOnReceiptMenuItemIngredient: ingredient,
                menuCategoryId,
                selected_menu_item_timestamp:menuItem.timestamp,
                loadingMenuCatgoeryItems: true,
                selectedOnReceiptItemType:'menu_item_ingredient',
                selectedItemType: 'menu_item',
                selectedMenuItemComboItemId: '',
                menuCategoryItemId,
                loadingMenuItemComboItems: true,
                loadingMenuItemWithIngredients: true,
                selectedMenuItemIngredientTypeCategory: 'no',
                selectedMenuItem:menuItem,
            });
            this.props.getMenuItemsByCategoryId(menuCategoryId).then(response =>{
                this.setState({loadingMenuCatgoeryItems:false})
            });
            // Menu Item 
            // console.log('menuCategoryItemId')
            // console.log(menuCategoryItemId)
            
            this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
                this.setState({loadingMenuItemComboItems:false})
            });
            this.props.fetchMenuItemByIdWithIngredients(menuCategoryItemId,'no').then(response => {
                this.setState({loadingMenuItemWithIngredients:false,isShowLoader:false})
            });
        }else if(type === 'menu_item_ingredient_notes'){
            this.setState({
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selectedOnReceiptMenuItemIngredientId: ingredient.timestamp,
                selectedOnReceiptMenuItemIngredient: ingredient,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptItemType:'menu_item_ingredient_notes',
                selectedItemType: 'menu_item',
                selectedMenuItem:menuItem,
            });
        }
    }
    handleOnClickSelectReceiptMenuItemComboItem = (menuItem,comboItem,type = 'combo_item') => {
        /* console.log('handleOnClickSelectReceiptMenuItemComboItem')
        console.log('handleOnClickSelectReceiptMenuItemComboItem')
        console.log('handleOnClickSelectReceiptMenuItemComboItem')
        console.log('handleOnClickSelectReceiptMenuItemComboItem')
        console.log('handleOnClickSelectReceiptMenuItemComboItem')
        console.log(menuItem)
        console.log(comboItem) */
        this.resetMenuScreen();
        let menuCategoryId = menuItem.menuCategoryId;
        let menuCategoryItemId = menuItem.menu_item_id;
        let menuItemComboItemId = comboItem._id;
        // alert(type)
        if(type === 'combo_item'){
            
            this.setState({
                isShowLoader:true,
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptMenuItemComboItemId:comboItem.selectedComboItemId,
                selectedOnReceiptItemType:'combo_item',
                selectedItemType:'combo_item',
                selectedMenuItemComboItemId:menuItemComboItemId,
                loadingMenuItemWithIngredients:true,
                selectedMenuItemIngredientTypeCategory:'no',
                menuCategoryId,
                menuCategoryItemId,
                loadingMenuCatgoeryItems: true,
                loadingMenuItemComboItems:true,
                selectedMenuItem:menuItem,
                selectedComboItem:comboItem,
            });
            this.props.getMenuItemsByCategoryId(menuCategoryId).then(response =>{
                this.setState({loadingMenuCatgoeryItems:false})
            });
            this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
                this.setState({loadingMenuItemComboItems:false})
            });
            this.props.fetchMenuItemByIdWithIngredients(menuItemComboItemId,'no').then(response => {
                this.setState({loadingMenuItemWithIngredients:false,isShowLoader:false})
            });
        }else if(type === 'combo_item_notes'){
            this.setState({
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptMenuItemComboItemId:comboItem.timestamp,
                selectedOnReceiptItemType:'combo_item_notes',
                selectedItemType:'combo_item_notes',
                selectedMenuItemComboItemId:comboItem.timestamp,
                menuCategoryItemId,
                selectedMenuItem:menuItem,
                selectedComboItem:comboItem,
            });
        }
    }

    handleOnClickSelectReceiptComboItemIngredient = (menuItem,comboItem,ingredient,type = 'combo_item_ingredient') =>{
        // console.log('menuItem')
        // console.log(menuItem)
        // console.log('comboItem')
        // console.log(comboItem)
        // console.log('ingredient')
        // console.log(ingredient)
        this.resetMenuScreen();
        let menuCategoryId = menuItem.menuCategoryId;
        let menuCategoryItemId = menuItem.menu_item_id;
        let menuItemComboItemId = comboItem._id;
        if(type === 'combo_item_ingredient'){
            this.setState({
                isShowLoader:true,
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptMenuItemComboItemId:comboItem.selectedComboItemId,
                selectedOnReceiptMenuItemComboItemIngredientId:ingredient._id,
                selectedOnReceiptMenuItemComboItemIngredient:ingredient,
                selectedOnReceiptItemType:'combo_item_ingredient',
                selectedItemType:'combo_item',
                selectedMenuItemComboItemId:menuItemComboItemId,
                loadingMenuItemWithIngredients:true,
                selectedMenuItemIngredientTypeCategory:'no',
                menuCategoryId,
                menuCategoryItemId,
                loadingMenuCatgoeryItems: true,
                loadingMenuItemComboItems:true,
                selectedMenuItem:menuItem,
                selectedComboItem:comboItem,
                selectedComboItemIngredient:ingredient,
            });
            this.props.getMenuItemsByCategoryId(menuCategoryId).then(response =>{
                this.setState({loadingMenuCatgoeryItems:false})
            });
            this.props.fetchMenuItemCombosByMenuItemId(menuCategoryItemId).then(response =>{
                this.setState({loadingMenuItemComboItems:false})
            });
            this.props.fetchMenuItemByIdWithIngredients(menuItemComboItemId,'no').then(response => {
                this.setState({loadingMenuItemWithIngredients:false,isShowLoader:false})
            });
        }else if(type === 'combo_item_ingredient_notes'){
            this.setState({
                selectedOnReceiptMenuItemId: menuCategoryItemId,
                selectedOnReceiptMenuItemTimeStamp: menuItem.timestamp,
                selected_menu_item_timestamp:menuItem.timestamp,
                selectedOnReceiptMenuItemComboItemId:comboItem.selectedComboItemId,
                selectedOnReceiptMenuItemComboItemIngredientId:ingredient.timestamp,
                selectedOnReceiptMenuItemComboItemIngredient:ingredient,
                selectedOnReceiptItemType:'combo_item_ingredient_notes',
                selectedItemType:'combo_item',
                selectedMenuItemComboItemId:menuItemComboItemId,
                menuCategoryId,
                menuCategoryItemId,
                selectedMenuItem:menuItem,
                selectedComboItem:comboItem,
                selectedComboItemIngredient:ingredient,
            });
        }
    }

    addNotesWithExtraCharge = (notes,extra_charges) =>{
        console.log('notes')
        console.log(notes)
        // console.log('extra_charges')
        // console.log(extra_charges)
        const timestamp = getUniqueNameWithTimeStamp();
        const selectedItemType = this.state.selectedItemType;
        const selectedOnReceiptItemType = this.state.selectedOnReceiptItemType;
        const selectedOnReceiptMenuItemIngredient = this.state.selectedOnReceiptMenuItemIngredient;
        const menu_item_id = this.state.menuCategoryItemId;
        const selected_menu_item_timestamp = this.state.selected_menu_item_timestamp;
        const combo_menu_item_id = this.state.selectedMenuItemComboItemId;
        const selectedOnReceiptMenuItemComboItemIngredient = this.state.selectedOnReceiptMenuItemComboItemIngredient;
        console.log('menu_item_id')
        console.log(menu_item_id)
        console.log('selectedItemType')
        console.log(selectedItemType)
        if(this.state.isRefundActive){
            let objNote = 
            {   
                item_type : 'notes',
                is_checked : true,
                notes,
                is_note : true,
                timestamp,
                // totalPrice:lastSelectedItem.selectedComboItem.combo_item_price,
            }
            if(extra_charges === 'no'){
                objNote.extra_charges = 0;
                objNote.price = 0;
                objNote.totalPrice = 0;
                objNote.is_paid_notes = false;
            }else{
                objNote.extra_charges = extra_charges;
                objNote.price = extra_charges;
                objNote.totalPrice = extra_charges;
                objNote.is_paid_notes = true;
            }
            console.log('objNote')
            console.log(objNote)
            this.props.addRefundedItemToCart(objNote,true,extra_charges,timestamp).then(response => {
                this.calculateTotal(this.props.cart);
            });
        }else{
            if(menu_item_id === '' && selectedItemType === ''){
                this.props.addNotesWithExtraChargeToCart(notes, extra_charges,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }else if(selectedItemType === 'menu_item'){
                let ingredient = 
                {   
                    is_checked : true,
                    menu_item_id : menu_item_id,
                    notes,
                    is_note : true,
                    timestamp,
                }
                if(extra_charges === 'no'){
                    ingredient.extra_charges = 0;
                    ingredient.price = 0;
                    ingredient.is_paid_notes = false;
                }else{
                    ingredient.extra_charges = extra_charges;
                    ingredient.price = extra_charges;
                    ingredient.is_paid_notes = true;
                }
                this.props.addNotesWithExtraChargeToCartMenuItem(ingredient, selectedItemType,selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredient, menu_item_id,selected_menu_item_timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }else if(selectedItemType === 'combo_item'){
                let ingredient = 
                {   
                    is_checked : true,
                    menu_item_id : menu_item_id,
                    combo_menu_item_id,
                    notes,
                    is_note : true,
                    timestamp,
                }
                if(extra_charges === 'no'){
                    ingredient.extra_charges = 0;
                    ingredient.price = 0;
                    ingredient.is_paid_notes = false;
                }else{
                    ingredient.extra_charges = extra_charges;
                    ingredient.price = extra_charges;
                    ingredient.is_paid_notes = true;
                }
                this.props.addNotesWithExtraChargeToCartMenuItemComboItem(ingredient, selectedItemType,selectedOnReceiptItemType,selectedOnReceiptMenuItemIngredient, menu_item_id,selected_menu_item_timestamp,combo_menu_item_id,selectedOnReceiptMenuItemComboItemIngredient).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }

        }
    }

    handleOnChangeCustomerData = (customer) =>{
        console.log('customer in handleOnChangeCustomerData')
        console.log(customer)
        this.setState({objCustomer:customer,distance_in_kilo:customer.distance_in_kilo,delivery_amount:customer.delivery_amount,selectedDeliveryPaymentMethod:customer.selectedDeliveryPaymentMethod}, () => {
            this.calculateTotal(this.props.cart);
        }); 
    }
    handleOnChangeDeliveryPaymentMethod = (selectedDeliveryPaymentMethod) =>{
        this.setState({selectedDeliveryPaymentMethod}) 
    }
    validateDoneOrder = (payment_method = null) =>{
        let error = false;
        let changeAmount = Number(parseFloat(Number(this.state.customerGivenAmount)).toFixed(2));
        // let totalAmountAfterTax = Number(parseFloat(this.state.totalAmountAfterTax).toFixed(2));
        let total_final = Number(calculateFinalTotalOfOrderWithOutObjOrder(this.state.total,this.state.delivery_amount,this.state.delivery_type,this.state.selectedDeliveryPaymentMethod,this.state.card_processing_fee));

        if(this.props.cart.length === 0){
            error = true;
            showMsg("Please add some items to the cart.",'error');
        }else if(this.state.selectedOrderType === ''){
            error = true;
            showMsg("Please select if this order is Delivery, Pickup, Dine-in or takeout.",'error');
        }else if(this.state.selectedOrderType === 'delivery' && Object.keys(this.state.objCustomer).length === 0){
            error = true;
            showMsg("Kindly Fill up the customer form.",'error');
        }else if(this.state.selectedOrderType === 'pickup' && Object.keys(this.state.objCustomer).length === 0){
            error = true;
            showMsg("Kindly Fill up the customer form.",'error');
        }else if(changeAmount !== 0 && (changeAmount !== 0.00 || changeAmount !== '0.00') && total_final > changeAmount ){
            error = true;
            showMsg("Insufficient amount.",'error');
        }

        
            
        let values = this.state.objCustomer;
        // console.log('values in validate')
        // console.log(values)
        const todayDateTime = new Date();
        /* if(values.timeSelectionRadioBox === "" || (values.timeSelectionRadioBox !== "have_ready_in" && values.timeSelectionRadioBox !== "other_time")){
            error = true;
            showMsg("Please select time from the customer form.",'error');
        }else */ 
        if(this.state.selectedOrderType === 'delivery' || this.state.selectedOrderType === 'pickup' ){
            // alert(payment_method)
            if(this.state.existing_order_id !== '' && (payment_method === 'cash' || payment_method === 'credit_card' || payment_method === 'debit_card' || payment_method === 'gift_card')){

            }else{
                if(values.timeSelectionRadioBox === "have_ready_in"){
                    const selectedDate = new Date(addTimeToCurrentDateTime(values.drp_default_time));
                    if(selectedDate > todayDateTime){
                    }else{
                        error = true;
                        showMsg("Please select time greater then current time.",'error');
                    }
                }else if(values.timeSelectionRadioBox === "other_time"){
                    const selectedDate = new Date(values.time_picker_time);
                    if(selectedDate > todayDateTime){
                    }else{
                        error = true;
                        showMsg("Please select time greater then current time.",'error');
                    }
                }
            }
        }

        return error;
    }
    validateDoneMistakeOrder = (payment_method) =>{
        let error = false;
        let changeAmount = Number(parseFloat(Number(this.state.customerGivenAmount)).toFixed(2));
        let total = Number(parseFloat(this.state.total).toFixed(2));
        // console.log('changeAmount')
        // console.log(changeAmount)
        // console.log('total')
        // console.log(total)
        if(this.props.cart.length === 0){
            error = true;
            showMsg("Please add some items to the cart.",'error');
        }else if(this.state.mistake_type !== 'new' && this.state.selectedOrderType === ''){
            error = true;
            showMsg("Please select if this order is Delivery, Pickup, Dine-in or takeout.",'error');
        }else if(Object.keys(this.state.objCustomer).length === 0){
            error = true;
            showMsg("Kindly Fill up the customer form.",'error');
        }else if(changeAmount !== 0 && (changeAmount !== 0.00 || changeAmount !== '0.00') && total > changeAmount ){
        // }else if(changeAmount !== 0 && total > changeAmount ){
            // }else if(changeAmount < total){
             
            error = true;
            showMsg("Insufficient amount.",'error');
        }

        
            
        let values = this.state.objCustomer;
        // console.log('values in validate')
        // console.log(values)
        // console.log('payment_method')
        // console.log('payment_method')
        // console.log('payment_method')
        // console.log('payment_method')
        // console.log(payment_method)
        const todayDateTime = new Date();
        if(payment_method !== 'done'){
            if(this.state.selectedOrderType === 'delivery' || this.state.selectedOrderType === 'pickup' ){
                if(values.timeSelectionRadioBox === "have_ready_in"){
                    const selectedDate = new Date(addTimeToCurrentDateTime(values.drp_default_time));
                    if(selectedDate > todayDateTime){
                    }else{
                        error = true;
                        showMsg("Please select time greater then current time.",'error');
                    }
                }else if(values.timeSelectionRadioBox === "other_time"){
                    const selectedDate = new Date(values.time_picker_time);
                    if(selectedDate > todayDateTime){
                    }else{
                        error = true;
                        showMsg("Please select time greater then current time.",'error');
                    }
                }
            }

        }
        

        return error;
    }
    handleOnClickDoneOrder = () => {
        if(this.state.isMistakeActive){
            let error = this.validateDoneMistakeOrder('done');
            // let error = this.validateDoneOrder();
            if(!error){
                this.setState({showDoneModal:true,payment_method:'done'});    
            }else{
                // console.log('correct the errors')
            }
        }
        /* else if(this.state.isRefundActive){
            let lastSelectedItem = this.state.lastSelectedItem;
            const timestamp = getUniqueNameWithTimeStamp();
            console.log('lastSelectedItem in handleOnClickDoneOrder')
            console.log(lastSelectedItem)
            if(lastSelectedItem.item_type === "menu_item"){
                this.props.addRefundedItemToCart(lastSelectedItem,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });;
                // We will have to create a function in store 
            }else if(lastSelectedItem.item_type === "combo_item"){
                let comboItem = 
                {   
                    item_type : lastSelectedItem.item_type,
                    menu_item_id : lastSelectedItem.menu_item_id,
                    menu_item_id_time_stamp : lastSelectedItem.menu_item_id_time_stamp,
                    price : lastSelectedItem.selectedComboItem.combo_item_price,
                    totalPrice:lastSelectedItem.selectedComboItem.combo_item_price,
                    menuCategoryId:this.state.menuCategoryId,
                    // is_checked : true,
                    // menu_item_id : menu_item_id,
                    // notes,
                    is_note : false,
                    timestamp,
                    ...lastSelectedItem.selectedComboItem,
                }
                
                
                this.props.addRefundedItemToCart(comboItem,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }else if(lastSelectedItem.item_type === "menu_item_ingredient"){
                
                let menu_item_ingredient = 
                {   
                    item_type : lastSelectedItem.item_type,
                    menu_item_id : lastSelectedItem.menu_item_id,
                    menu_item_id_time_stamp : lastSelectedItem.menu_item_id_time_stamp,
                    ...lastSelectedItem.ingredient,
                    menuCategoryId:this.state.menuCategoryId,
                    // is_checked : true,
                    // menu_item_id : menu_item_id,
                    // notes,
                    is_note : false,
                    timestamp,
                }
                console.log('lastSelectedItem in menu_item_ingredient')
                console.log(lastSelectedItem)
                console.log('lastSelectedItem.ingredientType')
                console.log(lastSelectedItem.ingredientType)
                if((lastSelectedItem.ingredient.ingredientType === 'extras_charged') || (lastSelectedItem.ingredient.ingredientType === 'both')){
                    console.log('i am in if')
                    menu_item_ingredient.totalPrice = lastSelectedItem.ingredient.price;
                }else{
                    console.log('i am in else')
                    menu_item_ingredient.totalPrice = 0;
                }
                console.log('menu_item_ingredient in menu_item_ingredient')
                console.log(menu_item_ingredient)
                this.props.addRefundedItemToCart(menu_item_ingredient,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }else if(lastSelectedItem.item_type === "combo_item_ingredient"){
                
                let combo_item_ingredient = 
                {   
                    item_type : lastSelectedItem.item_type,
                    menu_item_id : lastSelectedItem.menu_item_id,
                    menu_item_id_time_stamp : lastSelectedItem.menu_item_id_time_stamp,
                    menuCategoryId:this.state.menuCategoryId,
                    // totalPrice:lastSelectedItem.ingredient.price,
                    ...lastSelectedItem.ingredient,
                    // is_checked : true,
                    // menu_item_id : menu_item_id,
                    // notes,
                    is_note : false,
                    timestamp,
                }
                console.log('lastSelectedItem in combo_item_ingredient')
                console.log(lastSelectedItem)
                if((lastSelectedItem.ingredient.ingredientType === 'extras_charged') || (lastSelectedItem.ingredient.ingredientType === 'both')){
                    combo_item_ingredient.totalPrice = lastSelectedItem.ingredient.price;
                }else{
                    combo_item_ingredient.totalPrice = 0;
                }
                console.log('combo_item_ingredient in combo_item_ingredient')
                console.log(combo_item_ingredient)
                this.props.addRefundedItemToCart(combo_item_ingredient,false,null,timestamp).then(response => {
                    this.calculateTotal(this.props.cart);
                });
            }
            this.handelOnClickRefundMenu();
        } */
        else{
            if(this.state.existing_order_id !== ''){
                if((this.state.selectedExistingOrder.created_from === 'web' || this.state.selectedExistingOrder.created_from === 'tablet') && this.state.selectedExistingOrder.payment_method === 'credit_card'){
                    showMsg("This order has been paid, please select Credit or Debit.", 'error');
                    return true;
                }
                let conf = window.confirm('This order will be saved again in your existing orders.  Continue? ');
                if(conf === false){
                    return true;
                }
            }
            
            if ((this.state.existing_order_id !== '' && this.state.selectedExistingOrder.created_from !== 'tablet') && (this.state.selectedOrderType === 'dine_in' || this.state.selectedOrderType === 'takeout')){
                showMsg("Must select Pickup or Delivery.", 'error');
                // showMsg("Done is not allowed For Dine-in or takeout Delivery Type.", 'error');
            }else{
                let error = this.validateDoneOrder();
                if(!error){
                    this.setState({showDoneModal:true,payment_method:'done'});    
                }else{
                    // console.log('correct the errors')
                }
            }
            
        }
       
    }
    handleOnChangeDoneModalToggle = (showDoneModal) =>{
        this.setState({showDoneModal:showDoneModal});
    }

    submitDoneOrder = async () =>{
        let error = false;
        if(this.state.isMistakeActive){
            error = this.validateDoneMistakeOrder(this.state.payment_method);
        }else{
            error = this.validateDoneOrder(this.state.payment_method);
        }
        if(error){
            // console.log('correct the errors')
        }else{
            this.setState({isShowLoader:true});
            const cart = this.props.cart;
            let selectedExistingOrder = this.state.selectedExistingOrder;
            let values = this.state.objCustomer;
            
            values.phone_no = this.state.objCustomer.phone_no;
            // if it is existing delivery and pickup order and user cashes it through then dont update the times of order
            if(this.state.existing_order_id !== '' && (this.state.selectedOrderType === 'delivery' || this.state.selectedOrderType === 'pickup') && cashThroughTypes.includes(this.state.payment_method)){
                values.order_completion_datetime = this.state.selectedExistingOrder.order_completion_datetime;
            }else{
                console.log(' I am in else ')
                console.log(' I am in else ')
                console.log(' I am in else ')
                console.log(' I am in else ')
                console.log(' I am in else ')
                // if not existing pickup and delivery order and order is not getting cashed through then update the times of the order with the new values
                if(values.timeSelectionRadioBox === "have_ready_in"){
                    values.order_completion_datetime = addTimeToCurrentDateTime(values.drp_default_time);
                }else if(values.timeSelectionRadioBox === "other_time"){
                    values.order_completion_datetime = new Date(values.time_picker_time);
                }
            }
            
            values.cart = cart;
            // values.user = this.state.objCustomer;
            // values.total_amount = this.state.total;
            values.is_no_kitchen = this.state.isOrderNoKitchen;
            values.delivery_amount = this.state.delivery_amount;
            values.distance_in_kilo = this.state.distance_in_kilo;
            values.discount_amount = this.state.discount_amount;
            values.discount_percentage = this.state.discount_percentage;
            values.discount_amount_entered = this.state.discount_amount_entered;
            values.taxAmount = this.state.taxAmount;
            // values.total_amount_after_tax_delivery_charges = this.state.totalAmountAfterTax;
            values.taxPer = this.state.tax;
            values.subTotal = this.state.subTotal;
            values.total = this.state.total;
            
            values.province_name = this.state.restaurant_province;
            values.province_id = this.state.restaurant_province_id;
            values.payment_method = this.state.payment_method;
            values.customer_given_amount = this.state.customerGivenAmount;
            values.objCustomer = {
                // address: this.state.objCustomer.address,
                delivery_amount: this.state.objCustomer.delivery_amount,
                distance_in_kilo: this.state.objCustomer.distance_in_kilo,
                fullname: this.state.objCustomer.fullname,
                notes: this.state.objCustomer.notes,
                phone_no: this.state.objCustomer.phone_no,
                query: this.state.objCustomer.query,

                // drp_default_time: this.state.objCustomer.drp_default_time,
                // timeSelectionRadioBox: this.state.objCustomer.timeSelectionRadioBox,
                // time_picker_time: this.state.objCustomer.time_picker_time,
            }
            if(this.state.existing_order_id !== ''){
                values.objCustomer.appartment_unit_number = this.state.selectedExistingOrder.appartment_unit_number;
                values.appartment_unit_number = this.state.selectedExistingOrder.appartment_unit_number;
            }
            // if it is existing delivery and pickup order and user cashes it through then dont update the times of order
            if(this.state.existing_order_id !== '' && (this.state.selectedOrderType === 'delivery' || this.state.selectedOrderType === 'pickup') && cashThroughTypes.includes(this.state.payment_method)){
                values.objCustomer.drp_default_time = this.state.selectedExistingOrder.objCustomer.drp_default_time;
                values.objCustomer.timeSelectionRadioBox = this.state.selectedExistingOrder.objCustomer.timeSelectionRadioBox;
                values.objCustomer.time_picker_time = this.state.selectedExistingOrder.objCustomer.time_picker_time;
            }else{
                // if not existing pickup and delivery order and order is not getting cashed through then update the times of the order with the new values
                values.objCustomer.drp_default_time = this.state.objCustomer.drp_default_time;
                values.objCustomer.timeSelectionRadioBox = this.state.objCustomer.timeSelectionRadioBox;
                values.objCustomer.time_picker_time = this.state.objCustomer.time_picker_time;
            }
            if(this.state.isMistakeActive && this.state.payment_method === 'done'){
                values.delivery_type = '';
                values.address = '';
                values.objCustomer.address = '';
            }else{
                values.delivery_type = this.state.selectedOrderType;
                values.address = this.state.objCustomer.query;
                values.objCustomer.address = this.state.objCustomer.address;
            }
            
            if(values.delivery_type === 'dine_in' || values.delivery_type === 'takeout'){
                values.order_completion_datetime = new Date();
            }
            if(this.state.isMistakeActive){
                values.order_type = 'mistake';
            }else{
                values.order_type = 'regular';
            }

            values.delivery_payment_method = this.state.selectedDeliveryPaymentMethod;
            if(values.delivery_type === 'delivery' && this.state.selectedDeliveryPaymentMethod === 'credit'){
                values.card_processing_fee = this.state.card_processing_fee;
            }
            if(values.delivery_type === 'delivery'){
                values.total_including_fee = calculateTotalIncludingFee(values.total,values.delivery_amount,this.state.card_processing_fee,values.delivery_type,this.state.selectedDeliveryPaymentMethod);
            }
            values.subTotalBeforeTaxAndDiscount = (parseFloat(this.state.simpleItemsTotal) - parseFloat(this.state.refundItemsTotal)).toFixed(2);
            values.final_total = calculateFinalTotalOfOrder(values,this.state.selectedDeliveryPaymentMethod,this.state.card_processing_fee);

            console.log('values in submit order')
            console.log(values)
            let isConnected = await checkInternetConnection();
            if(this.state.existing_order_id !== ''){
                values.order_id = this.state.existing_order_id;
                this.props.submitEditOrder(values).then(response => {
                    this.setState({isShowLoader:false,showDoneModal:false});
                    let orderResponse = this.props.order.orderEditedSuccess;
                    if(orderResponse.error){
                        showMsg(orderResponse.msg,'error');
                    }else{
                        if(isConnected){
                            this.props.fetchMaxTicketNumberOfOrder().then(response => {
                                this.setState({maxTicketNumber:this.props.order.maxTicketNumber});
                            });
                        }
                        // alert('success')
                        console.log('orderResponse.objOrder')
                        console.log(orderResponse.objOrder)
                        showMsg(orderResponse.msg,'success');
                        // console.log('i have putOrder');
                        // console.log('orderResponse.order_id')
                        // console.log(orderResponse.order_id)
                        /* if(values.delivery_type === 'delivery' ){
                            
                        } */
                        this.handleOnClickVoidMenu();
                        this.setState({ lastOrderId: orderResponse.order_id, orderResponse: orderResponse.objOrder});
                        if(isConnected){
                            if(values.order_type === 'mistake' && values.payment_method !== 'done'){
                                socket.emit("putKitchenOrder", orderResponse.objOrder);
                                console.log('i am in edit socket')
                                console.log('i am in edit socket')
                                console.log('i am in edit socket')
                                console.log('i am in edit socket')
                            }else if((selectedExistingOrder.created_from === 'tablet' || selectedExistingOrder.created_from === 'web') && ((selectedExistingOrder.payment_method === null || selectedExistingOrder.payment_method === 'null') || (selectedExistingOrder.created_from === 'web' && (selectedExistingOrder.payment_method === 'credit_card' || selectedExistingOrder.payment_method === 'debit_card') && selectedExistingOrder.is_saved_through_pos === false) || (selectedExistingOrder.created_from === 'tablet' && (selectedExistingOrder.payment_method === 'credit_card' || selectedExistingOrder.payment_method === 'debit_card') && selectedExistingOrder.is_saved_through_pos === false))){
                            // else if((selectedExistingOrder.created_from === 'tablet' || selectedExistingOrder.created_from === 'web') && (selectedExistingOrder.payment_method === null || selectedExistingOrder.payment_method === 'null')){
                                // this.decrement('WebOrderNotificationsCount')
                                if(selectedExistingOrder.created_from === 'web'){
                                    socket.emit("OrderWebNotificationsCountDecrease",1);
                                }else if(selectedExistingOrder.created_from === 'tablet'){
                                    socket.emit("TabletOrderNotificationsCountDecrease",1);
                                }
                                console.log('hello in i am WebOrderNotificationsCount')
                                console.log('hello in i am WebOrderNotificationsCount')
                                console.log('hello in i am WebOrderNotificationsCount')
                                console.log('hello in i am WebOrderNotificationsCount')
                                if (orderResponse.objOrder.is_no_kitchen === false){
                                    socket.emit("putKitchenOrder", orderResponse.objOrder);
                                    if(selectedExistingOrder.created_from === 'web'){
                                        socket.emit("sendPushNotificationOfWebOrderFromPos", orderResponse.objOrder);
                                    }
                                }
                            }else{
                                if (orderResponse.objOrder.is_no_kitchen === false) {
                                    socket.emit("updateKitchenOrder", orderResponse.objOrder);
                                }
                            }
                        }
                        if(this.state.isOrderPrint){
                            // const lastOrderId = this.state.lastOrderId;
                            window.print();
                            // window.open(`print-pos-order/${orderResponse.order_id}`, '_blank');
                            this.setState({isOrderPrint:false})
                        }
                        // alert('sss')
                        // this.props.history.push(`/order-details/${orderResponse.order_id}`);
                    }
                });
            }else{
                this.props.submitCreateOrder(values).then(response => {
                    // console.log('this.props.order');
                    // console.log(this.props.order);
                    // if(orderSavedSuccess)
                    this.setState({isShowLoader:false,showDoneModal:false});
                    let orderResponse = this.props.order.orderSavedSuccess;
                    if(orderResponse.error){
                        showMsg(orderResponse.msg,'error');
                    }else{
                        if(isConnected){
                            this.props.fetchMaxTicketNumberOfOrder().then(response => {
                                this.setState({maxTicketNumber:this.props.order.maxTicketNumber});
                            });
                        }
                        // alert('success')
                        console.log('orderResponse.objOrder in submitCreateOrder')
                        console.log(orderResponse.objOrder)
                        showMsg(orderResponse.msg,'success');
                        // console.log('i have putOrder');
                        // console.log('orderResponse.order_id')
                        // console.log(orderResponse.order_id)
                        /* if(values.delivery_type === 'delivery' ){
                            
                        } */
                        this.setState({ lastOrderId: orderResponse.order_id, orderResponse: orderResponse.objOrder});
                        if(values.order_type === 'mistake' && values.payment_method === 'done'){
                        }else{
                            if (isConnected && this.state.isOrderNoKitchen === false){
                                socket.emit("putKitchenOrder", orderResponse.objOrder);
                            }
                            console.log('I am creating socket');
                        }
                        this.handleOnClickVoidMenu();

                        if(this.state.isOrderPrint){
                            // const lastOrderId = this.state.lastOrderId;
                            // window.open(`print-pos-order/${orderResponse.order_id}`, '_blank');
                            window.print();
                            this.setState({isOrderPrint:false})
                        }

                        // alert('sss')
                        // this.props.history.push(`/order-details/${orderResponse.order_id}`);
                    }
                });
            }
        }
    }

    onClickHandleLoadMoreExistingOrderItems = () => {
        console.log('onClickHandleLoadMoreExistingOrderItems')
        this.setState({isloadingExistingOrders:true});
        let skipExstingOrders = this.state.skipExstingOrders + this.state.limitExstingOrders;
        this.props.fetchOrders(
                'pos',
                'regular',
                'done',
                skipExstingOrders,
                this.state.limitExstingOrders,
                this.props.order.existingOrders
            ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            this.setState({skipExstingOrders,isloadingExistingOrders:false});
        });
    }
    onClickHandleLoadMoreWebExistingOrderItems = () => {
        console.log('onClickHandleLoadMoreWebExistingOrderItems')
        this.setState({isloadingWebExistingOrders:true});
        let skipWebExstingOrders = this.state.skipWebExstingOrders + this.state.limitWebExstingOrders;
        this.props.fetchWebOrders(
                'web',
                'regular',
                'done',
                skipWebExstingOrders,
                this.state.limitWebExstingOrders,
                this.props.order.webExistingOrders
            ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            this.setState({skipWebExstingOrders,isloadingWebExistingOrders:false});
        });
    }
    clearExistingOrderLimits = ()=>{
        this.setState({limitExstingOrders:7,skipExstingOrders:0});
    }
    clearWebExistingOrderLimits = ()=>{
        
        this.setState({limitWebExstingOrders:7,skipWebExstingOrders:0});
    }
    clearMistakeExistingOrderLimits = ()=>{
        this.setState({limitMistakeExstingOrders:7,skipMistakeExstingOrders:0});
    }
    onClickHandleLoadMoreMistakeExistingOrderItems = () => {
        console.log('onClickHandleLoadMoreMistakeExistingOrderItems')
        this.setState({isloadingMistakeExistingOrders:true});
        let skipMistakeExstingOrders = this.state.skipMistakeExstingOrders + this.state.limitMistakeExstingOrders;
        this.props.fetchMistakeOrders(
                'pos',
                'regular',
                'done',
                skipMistakeExstingOrders,
                this.state.limitMistakeExstingOrders,
                this.props.order.mistakeExistingOrders
            ).then(response => {
            console.log('this.props.order');
            console.log(this.props.order);
            this.setState({skipMistakeExstingOrders,isloadingMistakeExistingOrders:false});
        });
    }

    handelOnClickExistingOrder = (order) => {
        console.log('onClickExistingOrder')
        // console.log('order')
        // console.log(order);
        let existing_order_id = order._id;
        // let selectedExistingOrder = order;
        // console.log('order_id')
        // console.log(order_id)
        this.props.clearExistingOrderHistory();
        this.setState({isShowLoader:true,showExistingOrdersModal:false});
        this.handleOnClickVoidMenu();
        this.props.fetchOrderByOId(existing_order_id,'existing').then(response => {
            console.log('this.props.order.Order');
            console.log(this.props.order.Order);

            this.props.addExistingOrderToCart(this.props.order.Order.order_details).then(response => {
                this.calculateTotal(this.props.cart)
            })
            // changing the values
            let objState = {};
            const objOrder = this.props.order.Order;
            let objCustomer = objOrder.objCustomer;
            let customerFormData = objCustomer;
            
            objState.subTotal = objOrder.subTotal; 
            objState.total = objOrder.total; 
            objState.selectedDeliveryPaymentMethod = objOrder.delivery_payment_method; 
            objState.taxAmount = objOrder.taxAmount; 
            objState.delivery_amount = objOrder.delivery_amount; 
            objState.distance_in_kilo = objOrder.distance_in_kilo; 
            // objState.discount_amount = objOrder.discount_amount;
            if(objOrder.discount_amount !== 0 && objOrder.discount_amount !== '0' && objOrder.discount_amount !== null){
                objState.discount_amount = objOrder.discount_amount; 
            }else{
                objState.discount_amount = 0; 
            } 
            // objState.discount_percentage = objOrder.discount_percentage; 
            if(objOrder.discount_percentage !== 0 && objOrder.discount_percentage !== '0' && objOrder.discount_percentage !== null){
                objState.discount_percentage = objOrder.discount_percentage; 
            }else{
                objState.discount_percentage = 0; 
            }
            
            if(objOrder.discount_amount_entered !== 0 && objOrder.discount_amount_entered !== '0' && objOrder.discount_amount_entered !== null){
                objState.discount_amount_entered = objOrder.discount_amount_entered; 
            }else{
                objState.discount_amount_entered = 0; 
            }
            // objState.totalAmountAfterTax = objOrder.total_amount_after_tax_delivery_charges; 
            objState.selectedOrderType = objOrder.delivery_type; 
            objState.payment_method = objOrder.payment_method; 
            objState.customerGivenAmount = objOrder.customer_given_amount; 
            objState.isOrderNoKitchen = objOrder.is_no_kitchen; 
            if(objOrder.phone_no !== undefined){
                objState.phone_no = objOrder.phone_no;
                objState.value = objOrder.phone_no;
            }else{
                objState.phone_no = '';
                objState.value = '';
            }
            objState.maxTicketNumber = objOrder.ticket_no;
            objState.address = objOrder.address;
            objState.query = objOrder.address;
            if(customerFormData.timeSelectionRadioBox === "other_time"){
                objState.time_picker_time = customerFormData.time_picker_time;
            }
           
            this.setState({existing_order_id,selectedExistingOrder:this.props.order.Order,isShowLoader:false,...objState,objCustomer,customerFormData},()=>{
                this.calculateTotal(this.props.cart)
            });
        });
        
    }
    handelOnClickMistakeExistingOrder = (order) => {
        console.log('handelOnClickMistakeExistingOrder')
        console.log('order')
        console.log(order);
        let existing_order_id = order._id;
        
        this.props.clearMistakeExistingOrderHistory();
        this.setState({isShowLoader:true,showMistakeExistingOrdersModal:false});
        this.handleOnClickVoidMenu();
        this.props.fetchOrderByOId(existing_order_id,'mistake').then(response => {
            console.log('this.props.order.Order');
            console.log(this.props.order.Order);

            this.props.addExistingOrderToCart(this.props.order.Order.order_details).then(response => {
                this.calculateTotal(this.props.cart)
            })
            // changing the values
            let objState = {};
            const objOrder = this.props.order.Order;
            let objCustomer = objOrder.objCustomer;
            let customerFormData = objCustomer;

            objState.subTotal = objOrder.subTotal; 
            objState.total = objOrder.total; 
            objState.selectedDeliveryPaymentMethod = objOrder.delivery_payment_method; 
            objState.delivery_amount = objOrder.delivery_amount; 
            objState.distance_in_kilo = objOrder.distance_in_kilo; 
            objState.discount_amount = objOrder.discount_amount; 
            objState.discount_percentage = objOrder.discount_percentage; 
            objState.discount_amount_entered = objOrder.discount_amount_entered; 
            objState.taxAmount = objOrder.taxAmount; 
            // objState.totalAmountAfterTax = objOrder.total_amount_after_tax_delivery_charges; 
            objState.selectedOrderType = objOrder.delivery_type; 
            objState.payment_method = objOrder.payment_method; 
            objState.customerGivenAmount = objOrder.customer_given_amount; 
            objState.isOrderNoKitchen = objOrder.is_no_kitchen; 
            if(objOrder.phone_no !== undefined){
                objState.phone_no = objOrder.phone_no;
                objState.value = objOrder.phone_no;
            }else{
                objState.phone_no = '';
                objState.value = '';
            }
            objState.maxTicketNumber = objOrder.ticket_no;
            objState.address = objOrder.address;
            objState.query = objOrder.address;
            this.setState({isMistakeActive:true,mistake_type :'existing',existing_order_id,selectedExistingOrder:this.props.order.Order,isShowLoader:false,...objState,objCustomer,customerFormData},()=>{
                this.calculateTotal(this.props.cart)
            });
        });
        
    }
    handelOnClickWebExistingOrder = (order) => {
        console.log('handelOnClickWebExistingOrder')
        console.log('order')
        console.log(order);
        if(this.state.play){
            this.toggleNotificationAudioPlay();
            socket.emit("closeWebOrderAudio", 'audio_close');
        }
        let existing_order_id = order._id;
        // let selectedExistingOrder = order;
        // console.log('order_id')
        // console.log(order_id)
        this.props.clearWebExistingOrderHistory();
        this.setState({isShowLoader:true,showWebOrdersNotificationModal:false});
        this.handleOnClickVoidMenu();
        this.props.fetchOrderByOId(existing_order_id).then(response => {
            console.log('this.props.order.Order');
            console.log(this.props.order.Order);

            this.props.addExistingOrderToCart(this.props.order.Order.order_details).then(response => {
                this.calculateTotal(this.props.cart)
            })
            // changing the values
            let objState = {};
            const objOrder = this.props.order.Order;
            let objCustomer = objOrder.objCustomer;
            let customerFormData = objCustomer;

            objState.subTotal = objOrder.subTotal; 
            objState.total = objOrder.total; 
            objState.selectedDeliveryPaymentMethod = objOrder.delivery_payment_method; 
            objState.taxAmount = objOrder.taxAmount; 
            objState.delivery_amount = objOrder.delivery_amount; 
            objState.distance_in_kilo = objOrder.distance_in_kilo; 
            objState.discount_amount = objOrder.discount_amount; 
            objState.discount_percentage = objOrder.discount_percentage; 
            objState.discount_amount_entered = objOrder.discount_amount_entered; 
            objState.selectedOrderType = objOrder.delivery_type; 
            objState.payment_method = objOrder.payment_method; 
            objState.customerGivenAmount = objOrder.customer_given_amount; 
            if(objOrder.phone_no !== undefined){
                objState.phone_no = objOrder.phone_no;
                objState.value = objOrder.phone_no;
            }else{
                objState.phone_no = '';
                objState.value = '';
            }
            objState.maxTicketNumber = objOrder.ticket_no;
            objState.address = objOrder.address;
            objState.query = objOrder.address;
            console.log('objState after done')
            console.log('objState after done')
            console.log(objState)
           
            this.setState({existing_order_id,selectedExistingOrder:this.props.order.Order,isShowLoader:false,...objState,objCustomer,customerFormData},()=>{
                this.calculateTotal(this.props.cart)
            });
        });
        
    }
    

    handelOnClickRefundMenu = () => {
        console.log('handelOnClickRefundMenu')
        if(this.state.isMistakeActive){
            // if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            showMsg("Refund items can not be added for the Miskate Order.",'error');
        }else{
            this.setState({menuCategoryId:'',isRefundActive:!this.state.isRefundActive},()=>{
                console.log('state set')
                this.resetMenuScreen();
            });
        }
    }

    validateNotificationOrder = (payment_method) =>{
        let error = false;
        if(this.state.existing_order_id !== ''){
            // let selectedExistingOrder = this.state.selectedExistingOrder;
            // if(selectedExistingOrder.created_from === 'web' && (selectedExistingOrder.payment_method === null || selectedExistingOrder.payment_method === 'null')){
            //     showMsg("Credit and Debit will not work for new Website Notification Order.",'error');
            //     error = true;
            // }
            // if(selectedExistingOrder.created_from === 'web' && (payment_method === 'cash' || payment_method === 'gift')){
            //     showMsg("Cash and Gift are not allowed work for Website Notification Order.",'error');
            //     error = true;
            // }
        }
        return error;
    }

    handelOnClickCashMenu = () => {
        console.log('handelOnClickCashMenu')
        if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            showMsg("Cash will not work for new Mistake Order.",'error');
        }else if(this.state.isRefundActive){
            showMsg("Refund mode is activated kindly disable that to cash through.",'error');
        }else if (this.state.existing_order_id === '' && (this.state.selectedOrderType === 'pickup' || this.state.selectedOrderType === 'delivery')) {
            showMsg("Cash will not work for new Order with Delivery Type Pickup or Delivery", 'error');
        }else if(this.state.existing_order_id !== '' && (this.state.selectedExistingOrder.created_from === 'web') && this.state.selectedExistingOrder.payment_method === 'credit_card'){
            showMsg("This order has been paid, please select Credit or Debit.", 'error');
        }else if(this.state.existing_order_id !== '' && (this.state.selectedExistingOrder.created_from === 'web') && (this.state.selectedExistingOrder.payment_method === null || this.state.selectedExistingOrder.payment_method === 'null')){
            showMsg("Cash will not work for new mobile orders.", 'error');
        }else{
            let error = this.validateDoneOrder('cash');
            let errorNotificationOrder = this.validateNotificationOrder('cash');
            if(!error && !errorNotificationOrder){
                this.setState({showCashModal:true,payment_method:'cash'},()=>{
                    this.calculateChangeAmount();
                    // this.submitDoneOrder();
                });
            }else{
                // console.log('correct the errors')
            }
        }
    }
    handleOnChangeCashModalToggle = (showCashModal,isClearCustomerGivenAmount) =>{
        this.setState({showCashModal:showCashModal},()=>{

            if(!this.state.showCashModal){
                if(isClearCustomerGivenAmount){
                    this.setState({changeAmount:0,customerGivenAmount:''})
                }
            }
        });
    }
    handelOnClickMistakeMenu = () => {
        let error = false;
        if(this.state.isMistakeActive){
            // this.setState({isMistakeActive:false,mistake_type:''});
            this.handleOnClickVoidMenu();
        }else{
            if(this.props.cart.length > 0){
                error = true;
                showMsg("Mistake can not be activated with items preselected, delete items first.",'error');
            }
            if(!error){
                this.setState({showIsNewExistingMistakeSelectionModal:true});
            }
        }
    }
    handelOnClickDebitCardMenu = () => {
        console.log('handelOnClickDebitCardMenu')
        if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            showMsg("Debit will not work for new Mistake Order.",'error');
        }else if(this.state.isRefundActive){
            showMsg("Refund mode is activated kindly disable that to cash through.",'error');
        }else if (this.state.existing_order_id === '' && (this.state.selectedOrderType === 'pickup' || this.state.selectedOrderType === 'delivery')) {
            showMsg("Debit Card will not work for new Order with Delivery Type Pickup or Delivery", 'error');
        }else if(this.state.existing_order_id !== '' && (this.state.selectedExistingOrder.created_from === 'web') && (this.state.selectedExistingOrder.payment_method === null || this.state.selectedExistingOrder.payment_method === 'null')){
            showMsg("Debit Card will not work for new mobile orders.", 'error');
        }else{
            console.log('handelOnClickDebitCardMenu')
            console.log('this.props.cart')
            console.log(this.props.cart)
            console.log('this.state.selectedOrderType')
            console.log(this.state.selectedOrderType)
            console.log(this.state.objCustomer);
            let error = this.validateDoneOrder('debit_card');
            let errorNotificationOrder = this.validateNotificationOrder('debit');
            if(!error && !errorNotificationOrder){
                this.setState({showCashModal:true,payment_method:'debit_card'},()=>{
                    this.calculateChangeAmount();
                    // this.submitDoneOrder();
                });
            }else{
                // console.log('correct the errors')
            }
        }
    }
    handelOnClickCreditCardMenu = () => {
        console.log('handelOnClickCreditCardMenu');
        if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            showMsg("Credit Card will not work for new Mistake Order.",'error');
        }else if(this.state.isRefundActive){
            showMsg("Refund mode is activated kindly disable that to cash through.",'error');
        } else if (this.state.existing_order_id === '' && (this.state.selectedOrderType === 'pickup' || this.state.selectedOrderType === 'delivery')) {
            showMsg("Credit Card will not work for new Order with Delivery Type Pickup or Delivery", 'error');
        }else if(this.state.existing_order_id !== '' && (this.state.selectedExistingOrder.created_from === 'web') && (this.state.selectedExistingOrder.payment_method === null || this.state.selectedExistingOrder.payment_method === 'null')){
            showMsg("Credit Card will not work for new mobile orders.", 'error');
        }else{
            console.log('handleOnClickDoneOrder')
            console.log('this.props.cart')
            console.log(this.props.cart)
            console.log('this.state.selectedOrderType')
            console.log(this.state.selectedOrderType)
            console.log(this.state.objCustomer);
            let error = this.validateDoneOrder('credit_card');
            let errorNotificationOrder = this.validateNotificationOrder('credit');
            if(!error && !errorNotificationOrder){
                this.setState({showCashModal:true,payment_method:'credit_card'},()=>{
                    this.calculateChangeAmount();
                    // this.submitDoneOrder();
                });
            }else{
                // console.log('correct the errors')
            }
        }
    }
    handelOnClickGiftCardMenu = () => {
        console.log('handelOnClickGiftCardMenu')
        // alert('handelOnClickGiftCardMenu')
        if(this.state.isMistakeActive && this.state.mistake_type === 'new'){
            showMsg("Gift Card will not work for new Mistake Order.",'error');
        }else if(this.state.isRefundActive){
            showMsg("Refund mode is activated kindly disable that to cash through.",'error');
        } else if (this.state.existing_order_id === '' && (this.state.selectedOrderType === 'pickup' || this.state.selectedOrderType === 'delivery')) {
            showMsg("Gift Card will not work for new Order with Delivery Type Pickup or Delivery", 'error');
        }else if(this.state.existing_order_id !== '' && (this.state.selectedExistingOrder.created_from === 'web') && this.state.selectedExistingOrder.payment_method === 'credit_card'){
            showMsg("This order has been paid, please select Credit or Debit.", 'error');
            return true;
        }else if(this.state.existing_order_id !== '' && (this.state.selectedExistingOrder.created_from === 'web') && (this.state.selectedExistingOrder.payment_method === null || this.state.selectedExistingOrder.payment_method === 'null')){
            showMsg("Gift Card will not work for new mobile orders.", 'error');
        }else{
            console.log('handleOnClickDoneOrder')
            console.log('this.props.cart')
            console.log(this.props.cart)
            console.log('this.state.selectedOrderType')
            console.log(this.state.selectedOrderType)
            console.log(this.state.objCustomer);
            let error = this.validateDoneOrder('gift_card');
            let errorNotificationOrder = this.validateNotificationOrder('gift');
            if(!error && !errorNotificationOrder){
                this.setState({showCashModal:true,payment_method:'gift_card'},()=>{
                    this.calculateChangeAmount();
                    // this.submitDoneOrder();
                });
            }else{
                // console.log('correct the errors')
            }
        }
    }

    handleOnClickKeyPadButton = (button) => {
        console.log('button')
        console.log(button)
        console.log('this.state.result + button')
        console.log(this.state.result + button)
        if(button === "clear"){
            // this.reset()
            this.setState({
                result: '',
                customerGivenAmount:''
            })
        }else {
            this.setState({
                result: this.state.result + button,
                customerGivenAmount:this.state.result + button
            })
        }
    }
    handlekeyPadInputValueChange = (e) => {
        if (isNaN(Number(e.target.value))) {
            return;
        } else {
            this.setState({customerGivenAmount: e.target.value});
        }
    }
    handleOnChangeNewOrExistingMistakeModalToggle = (showIsNewExistingMistakeSelectionModal) =>{
        this.setState({showIsNewExistingMistakeSelectionModal:showIsNewExistingMistakeSelectionModal});
    }
    handleOnClickMistakeTypeSelection = (event,mistake_type) => {
        this.setState({showIsNewExistingMistakeSelectionModal:false,selectedMistakeType:mistake_type});
        if(mistake_type === 'new'){
            this.resetMenuScreen();
            this.setState({showCustomerInformationModal:true,isMistakeActive:true,mistake_type});
        }else if(mistake_type === 'existing'){
            this.setState({showMistakeExistingOrdersModal:true,isloadingMistakeExistingOrders:true,mistake_type});
            this.props.fetchMistakeOrders(
                'pos',
                'mistake',
                'done',
                this.state.skipMistakeExstingOrders,
                this.state.limitMistakeExstingOrders,
                this.props.order.mistakeExistingOrders
            ).then(response => {
                console.log('this.props.order');
                console.log(this.props.order);
                this.setState({isloadingMistakeExistingOrders:false});
            });
        }
    }
    handelOnClickNoKitchen = () => {
        this.setState({ isOrderNoKitchen: true },() =>{
            // this.submitDoneOrder();
        });
        /* const lastOrderId = this.state.lastOrderId;
        window.open(`print-pos-order/${lastOrderId}`, '_blank'); */
        // this.state.lastOrderId
    }

    handelOnClickPrint = () => {
        // alert('handelOnClickPrint');
        this.setState({isOrderPrint:true},()=>{
            this.submitDoneOrder();
        });
        /* const lastOrderId = this.state.lastOrderId;
        window.open(`print-pos-order/${lastOrderId}`, '_blank'); */
        // this.state.lastOrderId
    }
    handelOnClickChanegModalPrint = () => {
        // alert('handelOnClickPrint');
        // const lastOrderId = this.state.lastOrderId;
        // window.open(`print-pos-order/${lastOrderId}`, '_blank');
        window.print();
        // this.state.lastOrderId
    }
    
    
    render() {
        // this.handleOnClickVoidMenu();
        
        const menuCategories = this.props.menuCategories;
        const MenuItems = this.props.menuCategories.MenuItems;
        const total_menu_items = this.props.menuCategories.total_menu_items;
        const MenuItemCombos = this.props.menuItem.MenuItemCombos;
        const total_menu_item_combos = this.props.menuItem.total_menu_item_combos;
        const menuItemWithIngredient = this.props.menuItemWithIngredient.menuItemWithIngredient;
        const order = this.props.order;
        // console.log('this.state.selectedDeliveryPaymentMethod')
        // console.log(this.state.selectedDeliveryPaymentMethod)
        let total_sauce_ingredients = 0;
        let total_meat_cheese_ingredients = 0;
        let total_misc_ingredients = 0;
        if(Object.keys(menuItemWithIngredient).length > 0){
            const resultSauces = menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.type === "sauces");
            const resultMeatCheese = menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.type === "meat_cheese");
            const resultMisc = menuItemWithIngredient.Ingredients.filter(ingredient => ingredient.type === "misc");
            total_sauce_ingredients = resultSauces.length; 
            total_meat_cheese_ingredients = resultMeatCheese.length; 
            total_misc_ingredients = resultMisc.length; 
        }
        // console.log('order in render')
        // console.log(order)
        // console.log(total_meat_cheese_ingredients)
        // console.log(total_misc_ingredients)
        // console.log('menuCategories.MenuCategories')
        // console.log(menuCategories.MenuCategories)
        // console.log('this.props.objCustomer in cus')
        // console.log('checkInternetConnection()')
        // console.log(checkInternetConnection())
        
        return (
            <Fragment>
                <LoadingOverlay
                    active={this.state.isSystemBeingUpdate}
                    spinner
                    text='System is being updated.'
                >
                    <section className="homepage-main body-bg no-print">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-8">
                                    {/* <button onClick={this.toggleNotificationAudioPlay}>{this.state.play ? 'Pause' : 'Play'}</button> */}
                                    
                                    {/* <Offline>
                                        {showMsg("Disocunt Can not be added for the Miskate Order.",'error')}
                                    </Offline> */}
                                    <LoadingCircle 
                                        isShowLoader={this.state.isShowLoader}
                                    />
                                    <FoodMenu 
                                        menuCategories={menuCategories} 
                                        MenuItems={MenuItems} 
                                        order={order} 
                                        onClickHandleLoadMoreExistingOrderItems={this.onClickHandleLoadMoreExistingOrderItems} 
                                        total_menu_items={total_menu_items} 
                                        MenuItemCombos={MenuItemCombos} 
                                        total_menu_item_combos={total_menu_item_combos} 
                                        menuItemWithIngredient={menuItemWithIngredient} 
                                        total_sauce_ingredients={total_sauce_ingredients} 
                                        total_meat_cheese_ingredients={total_meat_cheese_ingredients} 
                                        total_misc_ingredients={total_misc_ingredients} 
                                        // Functions
                                        handleOnClickOrderType={this.handleOnClickOrderType} 
                                        handleOnChangeCustomerModalToggle={this.handleOnChangeCustomerModalToggle} 
                                        handleOnChangeNewOrExistingCustomerModalToggle={this.handleOnChangeNewOrExistingCustomerModalToggle} 
                                        handleOnChangeNewOrExistingTabletOrderNotificationSelectionModal={this.handleOnChangeNewOrExistingTabletOrderNotificationSelectionModal} 
                                        handleOnChangeExistingOrderModalToggle={this.handleOnChangeExistingOrderModalToggle} 
                                        handleOnClickCustomerTypeSelection={this.handleOnClickCustomerTypeSelection} 
                                        handleOnClickTabletOrderNotificationTypeSelection={this.handleOnClickTabletOrderNotificationTypeSelection} 
                                        clearDataOnMenuCategoryChange={this.clearDataOnMenuCategoryChange} 
                                        clearDataOnMenuItemChange={this.clearDataOnMenuItemChange} 
                                        clearDataOnMenuItemComboItemChange={this.clearDataOnMenuItemComboItemChange} 
                                        clearDataOnMenuItemIngredientTypeChange={this.clearDataOnMenuItemIngredientTypeChange} 
                                        handleOnClickMenuCategory={this.handleOnClickMenuCategory} 
                                        handleOnClickMenuCategoryItem={this.handleOnClickMenuCategoryItem} 
                                        handleOnClickMenuItemComboItem={this.handleOnClickMenuItemComboItem} 
                                        handleOnClickChangeMenuItemIngredientType={this.handleOnClickChangeMenuItemIngredientType} 
                                        loadMoreMenuItems={this.loadMoreMenuItems} 
                                        loadMoreMenuItemComboItems={this.loadMoreMenuItemComboItems} 
                                        loadMoreSauceIngredients={this.loadMoreSauceIngredients} 
                                        loadMoreMeatCheeseIngredients={this.loadMoreMeatCheeseIngredients} 
                                        loadMoreMiscIngredients={this.loadMoreMiscIngredients}
                                        addIngredientToMenuItemCart={this.addIngredientToMenuItemCart}
                                        addNotesWithExtraCharge={this.addNotesWithExtraCharge}
                                        handleOnChangeCustomerData={this.handleOnChangeCustomerData}
                                        handleOnChangeDeliveryPaymentMethod={this.handleOnChangeDeliveryPaymentMethod}
                                        handleOnChangeCustomerDetailsState={this.handleOnChangeCustomerDetailsState}
                                        handelOnClickExistingOrder={this.handelOnClickExistingOrder}
                                        handelOnClickWebExistingOrder={this.handelOnClickWebExistingOrder}
                                        clearExistingOrderHistory={this.props.clearExistingOrderHistory}
                                        clearExistingOrderLimits={this.clearExistingOrderLimits}
                                        clearWebExistingOrderLimits={this.clearWebExistingOrderLimits}
                                        selectedMenuCategoryId={this.state.menuCategoryId}
                                        handleOnChangeWebOrdersNotificationModalToggle={this.handleOnChangeWebOrdersNotificationModalToggle}
                                        showWebNotificationsModal={this.showWebNotificationsModal}
                                        showTabletNotificationsModal={this.showTabletNotificationsModal}
                                        onClickHandleLoadMoreWebExistingOrderItems={this.onClickHandleLoadMoreWebExistingOrderItems}
                                        clearWebExistingOrderHistory={this.props.clearWebExistingOrderHistory}
                                        handleOnChangeDrfDefaultTime={this.handleOnChangeDrfDefaultTime}
                                        handleOnChangetimeSelectionRadioBox={this.handleOnChangetimeSelectionRadioBox}
                                        onClickHandleLoadMoreNewTabletNotificationOrderItems={this.onClickHandleLoadMoreNewTabletNotificationOrderItems}
                                        clearNewTabletNotificationOrderLimits={this.clearNewTabletNotificationOrderLimits}
                                        handleOnChangeNewTabletNotificationModalToggle={this.handleOnChangeNewTabletNotificationModalToggle}
                                        clearNewTabletNotificationOrdersHistory={this.props.clearNewTabletNotificationOrdersHistory}
                                        onClickHandleLoadMoreExistingTabletNotificationOrderItems={this.onClickHandleLoadMoreExistingTabletNotificationOrderItems}
                                        clearExistingTabletNotificationOrderLimits={this.clearExistingTabletNotificationOrderLimits}
                                        handleOnChangeExistingTabletNotificationModalToggle={this.handleOnChangeExistingTabletNotificationModalToggle}
                                        clearExistingTabletNotificationOrdersHistory={this.props.clearExistingTabletNotificationOrdersHistory}
                                        handelOnClickTabletNewOrder={this.handelOnClickTabletNewOrder}
                                        handleOnClickMenuCategoryMenuItemSize={this.handleOnClickMenuCategoryMenuItemSize}
                                        handleOnClickMenuCategoryMenuItemSpecialModifier={this.handleOnClickMenuCategoryMenuItemSpecialModifier}
                                        {...this.state}
                                    />
                                </div>
                                <div className="col-3">
                                    <FoodCart 
                                        cart={this.props.cart}
                                        maxTicketNumber={this.state.maxTicketNumber}
                                        selectedOrderType={this.state.selectedOrderType}
                                        discount_percentage={this.state.discount_percentage}
                                        discount_amount_entered={this.state.discount_amount_entered}
                                        delivery_amount={this.state.delivery_amount}
                                        selectedOnReceiptMenuItemId={this.state.selectedOnReceiptMenuItemId}
                                        selectedOnReceiptMenuItemTimeStamp={this.state.selectedOnReceiptMenuItemTimeStamp}
                                        selectedOnReceiptMenuItemIngredientId={this.state.selectedOnReceiptMenuItemIngredientId}
                                        selectedOnReceiptMenuItemIngredient={this.state.selectedOnReceiptMenuItemIngredient}
                                        selectedOnReceiptMenuItemComboItemId={this.state.selectedOnReceiptMenuItemComboItemId}
                                        selectedOnReceiptMenuItemComboItemIngredientId={this.state.selectedOnReceiptMenuItemComboItemIngredientId}
                                        selectedOnReceiptMenuItemComboItemIngredient={this.state.selectedOnReceiptMenuItemComboItemIngredient}
                                        selectedOnReceiptItemType={this.state.selectedOnReceiptItemType}
                                        subTotal={this.state.subTotal}
                                        total={this.state.total}
                                        objCustomer={this.state.objCustomer}
                                        calculateTotalAmount={this.calculateTotalAmount} 
                                        removeFromCart={this.removeFromCart}
                                        removeSubItemFromCart={(product,subProduct)=> this.removeSubItemFromCart(product,subProduct)}
                                        removeIngredientFromCartMenuItem={this.removeIngredientFromCartMenuItem}
                                        removeIngredientFromCartComboItem={this.removeIngredientFromCartComboItem}
                                        handleOnClickSelectReceiptMenuItem={this.handleOnClickSelectReceiptMenuItem}
                                        handleOnClickSelectReceiptMenuItemIngredient={this.handleOnClickSelectReceiptMenuItemIngredient}
                                        handleOnClickSelectReceiptMenuItemComboItem={this.handleOnClickSelectReceiptMenuItemComboItem}
                                        handleOnClickSelectReceiptComboItemIngredient={this.handleOnClickSelectReceiptComboItemIngredient}
                                        handleOnClickKeyPadButton={this.handleOnClickKeyPadButton}
                                        handlekeyPadInputValueChange={this.handlekeyPadInputValueChange}
                                        customerGivenAmount={this.state.customerGivenAmount}
                                        existing_order_id={this.state.existing_order_id}
                                        selectedExistingOrder={this.state.selectedExistingOrder}
                                        discount_amount={this.state.discount_amount}
                                        taxAmount={this.state.taxAmount}
                                        totalAmountAfterTax={this.state.totalAmountAfterTax}
                                        handleOnClickFoodCartRemoveIcon={this.handleOnClickFoodCartRemoveIcon}
                                        headerLogo={this.state.headerLogo}
                                        card_processing_fee={this.state.card_processing_fee}
                                        selectedDeliveryPaymentMethod={this.state.selectedDeliveryPaymentMethod}
                                        
                                    />
                                </div>
                                <div className="col-1">
                                    <FoodNavigation 
                                        cart={this.props.cart}
                                        orderResponse={this.state.orderResponse} 
                                        showDiscountModal={this.state.showDiscountModal} 
                                        showDoneModal={this.state.showDoneModal}
                                        isShowLoader={this.state.isShowLoader}
                                        // showCashModal={true}
                                        showCashModal={this.state.showCashModal}
                                        handleOnClickVoidMenu={this.handleOnClickVoidMenu}
                                        handleOnClickDoneOrder={this.handleOnClickDoneOrder}
                                        handleOnClickDiscountMenu={this.handleOnClickDiscountMenu}
                                        handleOnChangeDiscountModalToggle={this.handleOnChangeDiscountModalToggle}
                                        handleOnChangeDoneModalToggle={this.handleOnChangeDoneModalToggle}
                                        handelOnClickRefundMenu={this.handelOnClickRefundMenu}
                                        handelOnClickCashMenu={this.handelOnClickCashMenu}
                                        handleOnChangeCashModalToggle={this.handleOnChangeCashModalToggle}
                                        handelOnClickDebitCardMenu={this.handelOnClickDebitCardMenu}
                                        handelOnClickCreditCardMenu={this.handelOnClickCreditCardMenu}
                                        handelOnClickGiftCardMenu={this.handelOnClickGiftCardMenu}
                                        submitDoneOrder={this.submitDoneOrder}
                                        isRefundActive={this.state.isRefundActive}
                                        isMistakeActive={this.state.isMistakeActive} 
                                        existing_order_id={this.state.existing_order_id} 
                                        selectedOrderType={this.state.selectedOrderType} 
                                        total={this.state.total}
                                        changeAmount={this.state.changeAmount}
                                        calculateChangeAmount={this.calculateChangeAmount}
                                        handelOnClickMistakeMenu={this.handelOnClickMistakeMenu}
                                        showIsNewExistingMistakeSelectionModal={this.state.showIsNewExistingMistakeSelectionModal}
                                        handleOnClickMistakeTypeSelection={this.handleOnClickMistakeTypeSelection}
                                        onClickHandleLoadMoreMistakeExistingOrderItems={this.onClickHandleLoadMoreMistakeExistingOrderItems}
                                        handleOnChangeNewOrExistingMistakeModalToggle={this.handleOnChangeNewOrExistingMistakeModalToggle}
                                        showMistakeExistingOrdersModal={this.state.showMistakeExistingOrdersModal}
                                        order={this.props.order}
                                        onClickHandleLoadMoreMistakeExistingOrderItems={this.onClickHandleLoadMoreMistakeExistingOrderItems}
                                        limitMistakeExstingOrders={this.state.limitMistakeExstingOrders}
                                        isloadingMistakeExistingOrders={this.state.isloadingMistakeExistingOrders}
                                        handleOnChangeMistakeExistingOrderModalToggle={this.handleOnChangeMistakeExistingOrderModalToggle}
                                        handelOnClickMistakeExistingOrder={this.handelOnClickMistakeExistingOrder}
                                        clearMistakeExistingOrderHistory={this.props.clearMistakeExistingOrderHistory}
                                        clearMistakeExistingOrderLimits={this.clearMistakeExistingOrderLimits}
                                        handelOnClickPrint={this.handelOnClickPrint} 
                                        handelOnClickNoKitchen={this.handelOnClickNoKitchen} 
                                        handelOnClickChanegModalPrint={this.handelOnClickChanegModalPrint} 
                                        openPresentationUrl={this.openPresentationUrl} 
                                        updateSystem={this.updateSystem} 
                                        discount_percentage={this.state.discount_percentage}
                                        discount_amount_entered={this.state.discount_amount_entered}
                                        discount_amount={this.state.discount_amount}
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <OrderReceiptPopup
                        // cart={this.state.cart}
                        objOrder={this.state.orderResponse}
                        restaurant_name={this.state.restaurant_name}
                    />
                </LoadingOverlay>

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    // console.log('state in POS')
    // console.log(state)
    return {
        menuCategories: state.menuCategories,
        menuItem: state.menuItem,
        menuItemWithIngredient: state.menuItemWithIngredient,
        cart : state.cart,
        order : state.order,
        objtax : state.settings.objtax,
        objRestaurantDeliveryFees : state.settings.objRestaurantDeliveryFees,
        objRestaurantAddress : state.settings.objRestaurantAddress,
        objRestaurantLogo : state.settings.objRestaurantLogo,
        objRestaurantName : state.settings.objRestaurantName,
        MenuItems:state.MenuItems,
        objRestaurantProvince: state.settings.objRestaurantProvince,
        objRestaurantCardProcessingFee: state.settings.objRestaurantCardProcessingFee,
    };
}

// export default Home
export default connect(mapStateToProps,{
    fetchMenuCatgories,
    getMenuItemsByCategoryId,
    fetchMenuItemCombosByMenuItemId,
    fetchMenuItemByIdWithIngredients,
    clearMenuCategoryMenuItems,
    clearMenuItemComboItems,
    clearMenuItemIngredients,
    clearCart,
    addToCart,
    addIncludedAndBothIngredientsToMenuItemCart,
    addIncludedAndBothIngredientsToComboMenuItemCart,
    addComboItemToMenuItemCart,
    removeCartItem,
    removeIngredientFromCartMenuItem,
    removeCartSubItem,
    removeIngredientFromCartComboItem,
    addIngredientToMenuItemCartA,
    addIngredientToMenuItemComboItemCart,
    addNotesWithExtraChargeToCart,
    addNotesWithExtraChargeToCartMenuItem,
    addNotesWithExtraChargeToCartMenuItemComboItem,
    removeNotesFromCart,
    addRefundedItemToCart,
    fetchResturantTax,
    fetchRestaurantCardProcessingFee,
    fetchRestaurantProvince,
    fetchRestaurantName,
    fetchResturantDeliveryFees,
    fetchResturantAddress,
    fetchResturantLogo,
    submitCreateOrder,
    submitEditOrder,
    fetchMaxTicketNumberOfOrder,
    fetchOrders,
    fetchWebOrders,
    fetchMistakeOrders,
    fetchOrderByOId,
    deleteOrderByOId,
    fetchWebOrderNotificationsCount,
    fetchTabletOrderNotificationsCount,
    clearOrderHistory,
    clearExistingOrderHistory,
    clearWebExistingOrderHistory,
    clearMistakeExistingOrderHistory,
    addExistingOrderToCart,
    fetchMenuItems,
    fetchIngredients,
    fetchIngredientMenuItems,
    fetchTodayExistingOrders,
    fetchTodayMistakeOrders,
    fetchNewTabletNotificationOrders,
    fetchExistingTabletNotificationOrders,
    clearNewTabletNotificationOrdersHistory,
    clearExistingTabletNotificationOrdersHistory,
})(withRouter(POS));
